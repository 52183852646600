import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

const VALID_CONTENT_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

const CONTROLLER_NAME = 'file-field'

// Connects to data-controller="file-field"
export default class extends Controller {
  static classes = ['preview', 'over', 'uploading']

  static outlets = ['form']

  static targets = ['input', 'preview', 'svgPreview']

  static values = {
    actions: Array,
    hasPreview: Boolean,
    persisted: Boolean
  }

  connect () {
    this.#prepareForm(this.form)
    this.#prepareActions()

    document.documentElement.addEventListener('turbo:morph', this.afterMorph)
  }

  disconnect () {
    document.documentElement.removeEventListener('turbo:morph', this.afterMorph)
  }

  afterMorph = _event => {
    this.connect()
  }

  get form () {
    return this.element.closest('form')
  }

  get uploadUrl () {
    return this.inputTarget.dataset.directUploadUrl
  }

  choseFile () {
    Array.from(this.inputTarget.files).forEach(file => {
      this.#uploadFile(file)
    })
  }

  // called from DirectUpload
  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', event => {
      this.#updateUploadProgress(event)
    })
  }

  dropped (event) {
    event.preventDefault()

    this.element.classList.remove(this.overClass)

    if (!event.dataTransfer.items) {
      return
    }

    const items = [...event.dataTransfer.items]

    if (items.size < 1) {
      return
    }

    const item = items[0]

    if (item.kind !== 'file') {
      return
    }

    const file = item.getAsFile()

    this.#uploadFile(file)

    this.element.focus()
  }

  dragover (event) {
    event.preventDefault()

    this.element.classList.add(this.overClass)
  }

  dragleave () {
    this.element.classList.remove(this.overClass)
  }

  hasPreviewValueChanged (value) {
    if (value) {
      this.element.classList.add(this.previewClass)
    } else {
      this.element.classList.remove(this.previewClass)
    }
  }

  remove (event) {
    event.preventDefault()

    this.#clearPreview()

    this.inputTarget.value = ''

    this.#appendInput('')

    this.dispatch('changed')
  }

  #appendInput (value) {
    if (!this.hiddenInputField) {
      const hiddenField = document.createElement('input')
      hiddenField.name = this.inputTarget.name
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('data-source', CONTROLLER_NAME)

      this.formOutlet.element.appendChild(hiddenField)

      this.hiddenInputField = hiddenField
    }

    this.hiddenInputField.value = value
  }

  #clearPreview () {
    this.previewTarget.innerHTML = ''

    this.hasPreviewValue = false
  }

  #displayPreview (file) {
    this.#clearPreview()

    this.previewTarget.textContent = file.name

    this.hasPreviewValue = true
  }

  #isValidFileToUpload (file) {
    return VALID_CONTENT_TYPES.includes(file.type)
  }

  #prepareActions () {
    const actions = Array.from(this.actionsValue)

    const attr = this.element.getAttribute('data-action') ? this.element.getAttribute('data-action').split(' ') : []

    actions.forEach(action => {
      const [controller, method] = action.split('#')
      attr.push(`${CONTROLLER_NAME}:changed->${controller}#${method}`)
    })

    if (attr.length > 0) {
      this.element.setAttribute('data-action', attr.join(' '))
    }
  }

  // connect the closest form as an outlet so we can disable
  // submit button while uploading
  #prepareForm (form) {
    if (!form) {
      return
    }

    if (form.id === '') {
      form.id = `form-${window.crypto.randomUUID()}`
    }

    this.element.dataset.fileFieldFormOutlet = `#${form.id}`
  }

  #uploadFile (file) {
    if (!file) {
      return
    }

    if (!this.#isValidFileToUpload(file)) {
      return
    }

    if (!this.hasFormOutlet) {
      return
    }

    this.element.style.setProperty('--eg-image-field-progress', '0%')
    this.element.classList.add(this.uploadingClass)

    this.#displayPreview(file)

    const upload = new DirectUpload(file, this.uploadUrl, this)

    this.formOutlet.disableButton()

    upload.create((error, blob) => {
      if (error) {
        this.#clearPreview()
      } else {
        this.#appendInput(blob.signed_id)
      }

      this.element.classList.remove(this.uploadingClass)
      this.formOutlet.enableButton()

      this.dispatch('changed')
    })
  }

  #updateUploadProgress (event) {
    const loaded = parseInt(event.loaded)
    const total = parseFloat(event.total)

    if (total <= 0) {
      return
    }

    if (!isFinite(loaded) || !isFinite(total)) {
      return
    }

    const progress = Math.round((loaded / total) * 100)

    this.element.style.setProperty('--eg-image-field-progress', `${progress}%`)
  }
}
