import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="swap"
export default class extends Controller {
  static targets = ['item', 'field']

  static values = {
    active: Boolean
  }

  toggle (event) {
    event.preventDefault()

    this.itemTargets.forEach((item) => {
      item.classList.toggle('hidden')
    })

    if (!this.hasFieldTarget) {
      return
    }

    // field is hidden
    if (this.fieldTarget.offsetParent === null) {
      return
    }

    this.fieldTarget.focus()
  }
}
