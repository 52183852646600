import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="builder-progress"
export default class extends Controller {
  static outlets = ['builder-form']

  static targets = ['nextButton', 'previousButton', 'stepLink']

  static values = {
    body: String,
    exitPath: String
  }

  builderFormOutletConnected () {
    // if a form is connected, disable prefetching on submit
    // there are multple next buttons (one for desktop, one for mobile)
    this.nextButtonTargets.forEach(button => {
      button.dataset.turboPrefetch = 'false'
    })
  }

  builderFormOutletDisconnected () {
    this.nextButtonTargets.forEach(button => {
      button.dataset.turboPrefetch = 'true'
    })
  }

  // called when user clicks an item on progress bar
  chooseStep (event) {
    if (!this.#confirmPageChange()) {
      event.preventDefault()
    }
  }

  // called from builder form outlet when form is submitted
  disableSubmit () {
    this.nextButtonTargets.forEach(button => {
      button.setAttribute('disabled', 'disabled')
    })
  }

  // called from builder form outlet when form is submitted
  enableSubmit () {
    this.nextButtonTargets.forEach(button => {
      button.removeAttribute('disabled')
    })
  }

  nextStep (event) {
    if (this.hasBuilderFormOutlet) {
      event.preventDefault()
      this.builderFormOutlet.submit()
    }
  }

  previousStep (event) {
    if (!this.#confirmPageChange()) {
      event.preventDefault()
    }
  }

  saveAndExit (event) {
    if (this.hasBuilderFormOutlet) {
      event.preventDefault()

      this.builderFormOutlet.submitAndExit()
    } else {
      Turbo.visit(this.exitPathValue, {
        frame: '_top',
        action: 'advance'
      })
    }
  }

  // if we wanted to some sort of prevention of leaving without submission
  // would need to determine dirty form state
  #confirmPageChange () {
    if (!this.hasBuilderFormOutlet) {
      return true
    }

    // if (window.confirm('Are you sure you want to leave this page?')) {
    return true
  }
}
