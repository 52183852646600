import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static targets = ['submit']

  connect () {
    this.changes = {}
    this.input = debounce(this.inputDebounced, 150)
  }

  get form () {
    return this.element.closest('form')
  }

  get submitButton () {
    if (this.hasSubmitTarget) {
      return this.submitTarget
    }

    return this.form.querySelector('input[type=submit]')
  }

  changed (_event) {
    this.form.requestSubmit(this.submitButton)
  }

  clear (event) {
    event.preventDefault()

    this.changes = {}
    this.form.reset()
  }

  inputDebounced (event) {
    const value = event.target.value
    const name = event.target.name

    const previousValue = this.changes[name]

    if (value === previousValue) {
      return
    }

    // if the input is part of an array / checkboxes,
    // we can't store its name in the changes object,
    // because it will prevent the form from being submitted
    if (!name.endsWith('[]')) {
      this.changes[event.target.name] = value
    }

    this.changed(event)
  }
}
