import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

// Connects to data-controller="sidebar"
export default class extends Controller {
  static classes = ['active']

  static targets = ['item']

  static values = {
    currentItem: String
  }

  connect () {
    this.#highlightCurrentItem()

    this.itemTargetConnected = debounce(() => this.#itemDidConnect(), 100)
  }

  currentItemValueChanged () {
    this.#highlightCurrentItem()
  }

  #itemDidConnect () {
    this.#highlightCurrentItem()
  }

  #highlightCurrentItem () {
    this.itemTargets.forEach(item => {
      if (item.dataset.identifier === this.currentItemValue) {
        item.classList.add(...this.activeClasses)
      } else {
        item.classList.remove(...this.activeClasses)
      }
    })
  }
}
