import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['conversation']

  markAsCurrent (event) {
    this.conversationTargets.forEach((conversation) =>
      conversation.classList.remove('conversation--current')
    )

    event.currentTarget.classList.add('conversation--current')
  }
}
