import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="loading"
export default class extends Controller {
  static targets = ['label', 'spinner']

  get label () {
    if (!this.hasLabelTarget) {
      return null
    }

    return this.labelTarget.innerText
  }

  set label (value) {
    if (!this.hasLabelTarget) {
      return
    }

    this.labelTarget.innerText = value
  }

  show (_event) {
    if (this.hasSpinnerTarget) {
      return
    }

    const spinner = document.createElement('eg-loading')
    this.element.appendChild(spinner)
  }
}
