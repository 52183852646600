import { VueController } from 'util/vue-controller'

// Connects to data-controller="recipients-lists-form"
export default class extends VueController {
  static targets = ['field']

  static values = {
    account: String,
    recipients: Array,
    lists: Array,
    type: String
  }

  static vueComponent = 'list-recipients-form'

  async connect () {
    if (!this.hasAccountValue) {
      throw new Error('account value is required')
    }

    await this.loadVueComponent()
  }

  disconnect () {
    this.unloadVueComponent()
  }

  get props () {
    return {
      account: this.accountValue,
      recipients: this.recipientsValue,
      lists: this.listsValue,
      type: this.typeValue
    }
  }
}
