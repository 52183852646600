import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="blocks-list-placeholders"
export default class extends Controller {
  async connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    const { Sortable } = await import('sortablejs')

    this.placeholderSortable = new Sortable(this.element, {
      group: {
        name: 'blocks',
        pull: 'clone',
        put: false,
        revertClone: true
      },
      sort: false,
      animation: 150,
      chosenClass: 'block-placeholder--selected',
      dragClass: 'block-placeholder--dragging'
    })
  }

  disconnect () {
    if (this.placeholderSortable) {
      this.placeholderSortable.destroy()
    }

    this.placeholdersSortable = null
  }
}
