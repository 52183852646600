import { VueController } from 'util/vue-controller'

// Connects to data-controller="contacts-combo-box"
export default class extends VueController {
  static targets = ['field']

  static values = {
    account: String,
    recipients: Array,
    recents: Array,
    type: {
      type: String,
      default: 'standard'
    }
  }

  static vueComponent = 'contact-recipients-form'

  async connect () {
    if (!this.hasAccountValue) {
      throw new Error('account value is required')
    }

    await this.loadVueComponent()
  }

  disconnect () {
    this.unloadVueComponent()
  }

  get props () {
    return {
      account: this.accountValue,
      recipients: this.recipientsValue,
      recents: this.recentsValue,
      type: this.typeValue
    }
  }
}
