import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="builder-field"
export default class extends Controller {
  static outlets = ['builder-fieldset']

  static targets = ['input', 'hidden', 'hiddenInput']

  static values = {
    field: String
  }

  disable () {
    this.inputTarget.classList.add('hidden')
    this.hiddenTarget.classList.remove('hidden')

    this.hiddenInputTarget.value = this.fieldValue

    if (this.hasBuilderFieldsetOutlet) {
      this.builderFieldsetOutlet.save()
    }
  }

  enable () {
    this.inputTarget.classList.remove('hidden')
    this.hiddenTarget.classList.add('hidden')

    this.hiddenInputTarget.value = ''

    const input = this.inputTarget.querySelector('.form-control')

    if (input) {
      input.focus()
    }

    if (this.hasBuilderFieldsetOutlet) {
      this.builderFieldsetOutlet.save()
    }
  }
}
