import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-highlight"
export default class extends Controller {
  static classes = ['active']

  static targets = ['field']

  connect () {
    this.#updateClasses()
  }

  check () {
    this.#updateClasses()

    this.dispatch('change')
  }

  changedElsewhere () {
    this.#updateClasses()
  }

  #updateClasses () {
    this.fieldTargets.forEach(field => {
      if (field.checked) {
        this.element.classList.add(this.activeClass)
      } else {
        this.element.classList.remove(this.activeClass)
      }
    })
  }
}
