import { Controller } from '@hotwired/stimulus'
import { capitalize, forEach } from 'lodash-es'

const ACTIONS = [
  'add',
  'delete'
]

// Connects to data-controller="bulk-select"
export default class extends Controller {
  static targets = [
    'checkbox', 'field', 'row', 'toolbar', 'headerCheckbox', 'count',
    ...ACTIONS.map(action => `${action}Button`)
  ]

  connect () {
    // shortcut to adding a few things manually each time
    window.addEventListener('turbo:before-cache', this.turboCache)
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('keyup', this.keyUp)
  }

  disconnect () {
    window.removeEventListener('turbo:before-cache', this.turboCache)
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('keyup', this.keyUp)
  }

  keyDown = event => this.enableShiftSelect(event)
  keyUp = event => this.disableShiftSelect(event)
  turboCache = event => this.reset(event)

  get selection () {
    const result = {
      ids: [],
      actions: new Set()
    }

    const possibleActions = {}

    this.selectedRows.forEach(row => {
      result.ids.push(row.dataset.identifier)

      let availableActions = []

      if (row.dataset.rowActions === 'all') {
        availableActions = ACTIONS
      } else {
        try {
          availableActions = JSON.parse(row.dataset.rowActions)
        } catch (_) {}
      }

      availableActions.forEach(action => {
        if (!possibleActions[action]) {
          possibleActions[action] = 0
        }

        possibleActions[action]++
      })
    })

    const size = result.ids.length

    // only use actions that are present in ALL selected messages
    forEach(possibleActions, (count, action) => {
      if (count === size) {
        result.actions.add(action)
      }
    })

    return result
  }

  get selectedCheckboxTargets () {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get selectedIds () {
    const result = []

    this.selectedRows.forEach(row => {
      result.push(row.dataset.identifier)
    })

    return result
  }

  get selectedRows () {
    const { selectedCheckboxTargets } = this

    return this.rowTargets.filter(rowTarget => selectedCheckboxTargets.some(checkbox => rowTarget.contains(checkbox)))
  }

  get tableViewElement () {
    const tableView = this.element.querySelector('[data-controller~="table-view"]')

    if (tableView) {
      return tableView
    }

    return this.element
  }

  closeKey (event) {
    if (event.key !== 'Escape') {
      return
    }

    if (this.hasToolbarTarget && this.toolbarTarget.open !== true) {
      return
    }

    this.reset()
  }

  check (event) {
    if (this.isShifting && this.lastCheckedCheckbox) {
      this.shiftSelect(this.lastCheckedCheckbox, event.target)
    }

    this.lastCheckedCheckbox = event.target

    this.update()
  }

  checkAll (event) {
    const isCheckedAll = this.selectedCheckboxTargets.length === this.checkboxTargets.length

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = !isCheckedAll
    })

    this.dispatch('checked')

    this.update()
  }

  disableShiftSelect (event) {
    if (event.key === 'Shift') {
      this.isShifting = false
    }
  }

  enableShiftSelect (event) {
    if (event.key === 'Shift') {
      this.isShifting = true
    }
  }

  reset (event) {
    if (event) {
      if ('open' in event.target && event.target.open) {
        return
      }
    }

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    this.dispatch('checked')

    this.update()
  }

  shiftSelect (from, to) {
    const start = this.checkboxTargets.indexOf(from)
    const end = this.checkboxTargets.indexOf(to)
    this.checkboxTargets.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(checkbox => {
      checkbox.checked = true
    })
  }

  toggleActionButton (target, isDisabled) {
    if (isDisabled) {
      target.disabled = true
      target.classList.add('hidden')
      target.setAttribute('aria-hidden', 'true')
    } else {
      target.disabled = false
      target.classList.remove('hidden')
      target.setAttribute('aria-hidden', 'false')
    }
  }

  update () {
    const { ids, actions } = this.selection
    const allChecksSize = this.checkboxTargets.length
    const count = ids.length

    this.fieldTargets.forEach(target => {
      target.value = ids
    })

    // dispatch event for other controllers
    this.dispatch('change', {
      detail: { count, ids, actions },
      target: this.tableViewElement
    })

    // update action buttons

    ACTIONS.forEach(action => {
      if (this[`has${capitalize(action)}ButtonTarget`]) {
        this.toggleActionButton(this[`${action}ButtonTarget`], actions.has(action) !== true)
      }
    })

    // update toolbar details

    if (this.hasToolbarTarget) {
      if (count > 0) {
        this.toolbarTarget.open = true
        this.toolbarTarget.focus()
      } else {
        this.toolbarTarget.open = false
      }
    }

    if (this.hasCountTarget) {
      this.countTarget.textContent = count
    }

    // update header checkbox

    if (!this.hasHeaderCheckboxTarget) {
      return
    }

    if (allChecksSize === count) {
      this.headerCheckboxTarget.checked = true
    } else if (count > 0) {
      this.headerCheckboxTarget.indeterminate = true
    } else {
      this.headerCheckboxTarget.checked = false
      this.headerCheckboxTarget.indeterminate = false
    }
  }
}
