import { VueController } from 'util/vue-controller'
import { strip } from 'util/string'

// Connects to data-controller="recipient-switch"
export default class extends VueController {
  static targets = ['field']

  static values = {
    account: String,
    recipientId: String,
    mode: {
      type: String,
      default: 'email'
    }
  }

  static vueComponent = 'recipient-search'

  async connect () {
    if (!this.hasAccountValue) {
      throw new Error('account value is required')
    }

    this.field.setAttribute('type', 'hidden')

    await this.loadVueComponent()
  }

  disconnect () {
    this.field.setAttribute('type', 'text')
    this.unloadVueComponent()
  }

  get field () {
    if (this.hasFieldTarget) {
      return this.fieldTarget
    } else {
      throw new Error('no field found')
    }
  }

  get placeholder () {
    return this.field.getAttribute('placeholder') ?? 'Search for a recipient...'
  }

  get props () {
    return {
      account: this.accountValue,
      placeholder: this.placeholder,
      mode: this.modeValue
    }
  }

  recipientIdValueChanged (value) {
    let recipientId = strip(`${value}`)

    if (recipientId === '' || recipientId === null || recipientId === undefined || recipientId === 'null') {
      recipientId = null
    }

    if (recipientId === null) {
      this.field.value = ''
    } else {
      this.field.value = recipientId
    }
  }
}
