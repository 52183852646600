let registered = false

export default async function (Quill) {
  if (registered) {
    return Quill
  }

  const Block = Quill.import('blots/block')

  // <p class="graf"> This is just a normal paragaph that does nothing
  class GrafFormat extends Block {
    static blotName = 'graf'
    static className = 'graf'
    static tagName = 'p'

    static formats (node) {
      return node.classList.value
    }

    format (name, value) {
      super.format(name, value)
    }
  }

  Quill.register('formats/graf', GrafFormat)

  registered = true

  return null
}
