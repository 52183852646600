import { Controller } from '@hotwired/stimulus'
import createBodyTheme from 'util/quill/body-theme'
import createRichTextFormat from 'util/quill/rich-text-format'
import creatFooterFormat from 'util/quill/footer-format'
import creatFooterLineFormat from 'util/quill/footer-line-format'
import createGrafFormat from 'util/quill/graf-format'
import setupIcons from 'util/quill/icons'
import { throttle } from 'lodash-es'

// Connects to data-controller="rich-text-field"
export default class extends Controller {
  static classes = ['focus']

  static targets = ['input', 'container', 'editor', 'toolbar']

  static values = {
    dirty: Boolean,
    focused: Boolean,
    placeholder: String,
    options: {
      type: Object,
      default: {
        theme: 'body'
      }
    }
  }

  async connect () {
    this.quill = await import('quill')

    this.onChange = throttle(() => {
      this.formWillSubmit()
      this.dispatch('change')
    }, 250)

    if (this.element.dataset.formTarget === 'primaryField') {
      this.element.closest('form').addEventListener('form:focus', this.onFormFocus)
    }

    this.#setup()
  }

  disconnect () {
    if (this.hasToolbarTarget) {
      this.toolbarTarget.remove()
    }

    this.editor = null
    this.quill = null

    if (this.form) {
      this.form.removeEventListener('submit', this.formWillSubmit)
    }

    this.element.closest('form').removeEventListener('form:focus', this.onFormFocus)
  }

  onFormFocus = _event => {
    this.focus()
  }

  get form () {
    if (!this._form) {
      this._form = this.element.closest('form')
    }

    return this._form
  }

  focus () {
    this.editor.focus()
  }

  focusedValueChanged () {
    if (this.focusedValue === true) {
      this.containerTarget.classList.add('ql-container--focused')

      if (this.hasToolbarTarget) {
        this.toolbarTarget.classList.add('ql-toolbar--focused')
      }

      if (this.hasFocusClass) {
        this.element.classList.add(...this.focusClasses)
      }

      this.dispatch('focus')
    } else {
      this.containerTarget.classList.remove('ql-container--focused')

      if (this.hasToolbarTarget) {
        this.toolbarTarget.classList.remove('ql-toolbar--focused')
      }

      if (this.hasFocusClass) {
        this.element.classList.remove(...this.focusClasses)
      }

      this.dispatch('blur')
    }
  }

  getHtml () {
    if (this.hasEditorTarget) {
      return this.editorTarget.innerHTML
    }

    return ''
  }

  receiveClick (event) {
    if (this.toolbarTarget.contains(event.target)) {
      this.toolbarDidFocus()
    } else {
      this.selectionDidChange()
    }
  }

  toolbarDidFocus () {
    this.focusedValue = true
  }

  formWillSubmit = _ => {
    if (this.dirtyValue === true) {
      this.inputTarget.value = this.getHtml()
    }
  }

  selectionDidChange = (position = null) => {
    let isFocused = false

    if (document.activeElement && this.element.contains(document.activeElement)) {
      isFocused = true
    }

    if (position === null && !isFocused) {
      this.focusedValue = false
    } else {
      this.focusedValue = true
    }
  }

  textDidChange = _ => {
    this.dirtyValue = true

    this.onChange()
  }

  #setup () {
    if (this.editor) {
      return
    }

    const options = this.optionsValue

    if (!options.theme) {
      options.theme = 'body'
    }

    if (this.hasPlaceholderValue) {
      options.placeholder = this.placeholderValue
    }

    const Quill = this.quill.default

    createBodyTheme(Quill)
    createRichTextFormat(Quill)
    setupIcons(Quill)
    creatFooterFormat(Quill)
    creatFooterLineFormat(Quill)
    createGrafFormat(Quill)
    this.editor = new Quill(this.containerTarget, options)

    this.editor.root.setAttribute('data-rich-text-field-target', 'editor')
    this.editor.theme.modules.toolbar.container.setAttribute('data-rich-text-field-target', 'toolbar')
    this.toolbarTarget.setAttribute('data-action', 'click->rich-text-field#toolbarDidFocus click@window->rich-text-field#receiveClick')

    this.editor.on('text-change', this.textDidChange)
    this.editor.on('selection-change', this.selectionDidChange)

    if (this.form) {
      this.form.addEventListener('submit', this.formWillSubmit)
    }
  }
}
