import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

const resizeElement = (element) => {
  element.style.height = 'auto'
  element.style.height = `${element.scrollHeight + 10}px`
}

const TEXT_AREA_OBSERVER = new window.IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      resizeElement(entry.target)
    }
  })
}, { threshold: 0 })

// Connects to data-controller="textarea-grow"
export default class extends Controller {
  connect () {
    this.onInput = debounce(this.onInputDebounced, 10)
    this.element.addEventListener('input', this.onInput)
    this.element.addEventListener('focus', this.onInput)

    this.#resize()

    TEXT_AREA_OBSERVER.observe(this.element)
  }

  disconnect () {
    this.element.removeEventListener('input', this.onInput)
    this.element.removeEventListener('focus', this.onInput)
    this.onInput = null

    TEXT_AREA_OBSERVER.unobserve(this.element)
  }

  onInputDebounced = (_event) => {
    this.#resize()
  }

  #resize () {
    resizeElement(this.element)
  }
}
