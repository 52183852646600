let headContents = ''

/* Utility to put contents within the head and replace the eg:head */
export default class EGHead extends HTMLElement {
  connectedCallback () {
    this.target = document.querySelector('meta[name="eg:head"]')
    this.endTarget = document.querySelector('meta[name="eg:head-end"]')

    if (this.target && this.endTarget) {
      const newHead = this.template.outerHTML

      if (headContents !== newHead) {
        this.#clearExisting()
        this.#cloneTemplate()

        headContents = newHead
      }
    }

    this.disconnect()
  }

  get template () {
    return this.querySelector('template')
  }

  disconnect () {
    try { this.remove() } catch {}
  }

  #clearExisting () {
    let next = this.target.nextElementSibling

    while (next && next !== this.endTarget) {
      const toRemove = next
      next = next.nextElementSibling
      toRemove.remove()
    }
  }

  #cloneTemplate () {
    this.target.after(this.template.content.cloneNode(true))
  }
}
