// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './elements'
import './controllers'
import 'form-request-submit-polyfill'
import { handleTurboStreamRender, handleLoadAction } from './util/turbo-stream'
import { setConfig } from './util/config'
import { StreamActions } from '@hotwired/turbo'
import { fetchHtml } from './util/fetch'

import LocalTime from 'local-time'

LocalTime.start()

document.addEventListener('turbo:before-stream-render', handleTurboStreamRender)

// reload local-time instances after a page morph
document.addEventListener('turbo:morph', () => LocalTime.run())

// override Content Missing default error
document.addEventListener('turbo:frame-missing', (event) => {
  event.target.innerHTML = '<eg-blank-state type="frame-missing"></eg-blank-state>'
  event.preventDefault()
})

StreamActions.load = () => handleLoadAction(this)

StreamActions.reload_body = async () => {
  const html = await fetchHtml(window.location.pathname, 'get', null, { 'Turbo-Frame': 'reload_body' })

  const container = document.createElement('turbo-container')
  container.innerHTML = html

  document.documentElement.appendChild(container)
}

// data pipeline of events captured before we finished loading this script
// catch up the events in the list, then set up future events to call directly

window.egData = window.egData || []

function executePageCommand () {
  const args = Array.prototype.slice.call(arguments)
  const context = args.shift()

  if (context === 'set') {
    setConfig.apply(undefined, args)
  }
}

const catchUp = window.egData

window.egData = {
  push: (args) => executePageCommand.apply(undefined, args)
}

Array.from(catchUp).forEach((args) => executePageCommand.apply(undefined, args))
