import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-view-collection-field"
//
// Listens for table-view-collection add/remove events and adds them
// to a form field anywhere on the page
export default class extends Controller {
  static values = {
    ids: Array
  }

  connect () {
    window.addEventListener('table-view-collection:add', this.onCollectionAdd)
    window.addEventListener('table-view-collection:remove', this.onCollectionRemove)
  }

  disconnect () {
    window.removeEventListener('table-view-collection:add', this.onCollectionAdd)
    window.removeEventListener('table-view-collection:remove', this.onCollectionRemove)
  }

  onCollectionAdd = event => {
    this.idsValue = [...this.idsValue, event.detail.id]
  }

  onCollectionRemove = event => {
    this.idsValue = this.idsValue.filter(id => id !== event.detail.id)
  }

  idsValueChanged (newValue) {
    if (newValue.length === 0) {
      this.element.value = 'none'
    } else {
      this.element.value = newValue.join(',')
    }
  }
}
