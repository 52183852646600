import { Controller } from '@hotwired/stimulus'
import { fetchTurboStream } from 'util/fetch'
import { csrfParams } from 'util/params'

// Connects to data-controller="blocks-list"
export default class extends Controller {
  static targets = ['block', 'list']

  static values = {
    url: String,
    reorderUrl: String
  }

  async connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    this.#toggleContainerClasses()

    const { Sortable } = await import('sortablejs')

    const destination = this.hasListTarget ? this.listTarget : this.element

    this.listSortable = new Sortable(destination, {
      group: 'blocks',
      animation: 150,
      handle: '[data-handle]',
      onAdd: async (event) => {
        // prevent re-ordering when adding a new block
        this.isAdding = true

        await this.#createBlockAt(event.item)

        this.isAdding = false
      },
      onSort: async (event) => {
        // no need to also re-order when adding a new block
        if (this.isAdding) {
          return
        }

        await this.#updateBlocksOrder()
      }
    })
  }

  disconnect () {
    if (this.listSortable) {
      this.listSortable.destroy()
    }

    this.listSortable = null
  }

  blockTargetConnected () {
    this.#toggleContainerClasses()
  }

  blockTargetDisconnected () {
    this.#toggleContainerClasses()
  }

  async #createBlockAt (item) {
    const itemId = window.crypto.randomUUID()
    item.id = itemId

    let after = null

    // if this is the first item, the previous sibling will be "#text" node
    if (item.previousElementSibling && item.previousElementSibling.nodeName === 'DIV') {
      after = item.previousElementSibling.dataset.blockId
    }

    const style = item.dataset.block
    const kind = item.dataset.kind ?? 'email'

    await fetchTurboStream(this.urlValue, 'post', csrfParams({
      placeholder_id: itemId,
      after,
      style,
      kind,
      type: style
    }))

    return true
  }

  async #updateBlocksOrder () {
    const blocks = this.blockTargets.map(block => block.dataset.blockId)

    await fetchTurboStream(this.reorderUrlValue, 'post', csrfParams({ blocks }))

    return true
  }

  #toggleContainerClasses () {
    const destination = this.hasListTarget ? this.listTarget : this.element

    const hasBlocks = this.blockTargets.length > 0

    if (hasBlocks) {
      destination.classList.remove('blocks-container--empty')
    } else {
      destination.classList.add('blocks-container--empty')
    }
  }
}
