import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="preview-frame"
export default class extends Controller {
  connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    this.resize()
  }

  resize () {
    const iframeContentHeight = this.element.contentWindow.document.body.scrollHeight

    this.element.style.height = `${iframeContentHeight + 50}px`
  }
}
