let registered = false

export default async function buildTheme (Quill) {
  if (registered) {
    return Quill
  }

  const SnowTheme = Quill.import('themes/snow')

  // const COLORS = [
  //   '#B0D3DF', '#000000', '#425F41', '#B5D6B0',
  //   '#D8C3C2', '#EC1F24', '#B5B4AF', '#F5DA95',
  //   '#211E53', '#E9802F', '#55415C', '#6C1C27'
  // ]

  const TOOLBAR_CONFIG = [
    [{ header: 2 }, 'graf', 'footer'],
    ['bold', 'italic', 'link'],
    [{ align: [false, 'center', 'right'] }],
    ['clean']
  ]

  class BodyTheme extends SnowTheme {
    constructor (quill, options) {
      super(quill, options)

      options.modules.toolbar.container = TOOLBAR_CONFIG

      this.quill.container.classList.add('ql-container--body')
      this.quill.root.classList.add('ql-editor--body')
      this.quill.root.classList.add('msg-rt')
    }

    extendToolbar (toolbar) {
      super.extendToolbar(toolbar)
      toolbar.container.classList.add('ql-toolbar--body')
    }
  }

  BodyTheme.DEFAULTS = {
    modules: SnowTheme.DEFAULTS.modules
  }

  Quill.register('themes/body', BodyTheme)

  registered = true

  return BodyTheme
}
