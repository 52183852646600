import { Controller } from '@hotwired/stimulus'
import { debounce, isEqual } from 'lodash-es'
import { serializeFormParams } from 'util/form'
import { csrfParams } from 'util/params'
import { fetchTurboStream } from 'util/fetch'

// Connects to data-controller="builder-fieldset"
export default class extends Controller {
  connect () {
    this.updating = false

    this.changes = {}
    this.changed = debounce(this.changedDebounced, 250)
  }

  get previewUrl () {
    return this.element.getAttribute('action')
  }

  changedDebounced (_event) {
    this.#updatePreview()
  }

  exitField (_event) {
    this.#updatePreview()
  }

  refresh (_event) {
    this.#updatePreview(true)
  }

  save (_event) {
    this.#updatePreview(true, 'quick_save')
  }

  async #updatePreview (force = false, commitMessage = 'preview') {
    if (this.updating) {
      return
    }

    this.updating = true

    const params = Object.assign({}, csrfParams({
      commit: commitMessage
    }), serializeFormParams(this.element))

    // only post an update if content has changed
    if (force === false && isEqual(this.changes, params)) {
      this.updating = false
      return
    }

    await fetchTurboStream(this.previewUrl, 'patch', params)

    this.changes = Object.assign({}, params)
    this.updating = false
  }
}
