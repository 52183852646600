import { each, map, isArray } from 'lodash-es'
import { csrfParams } from './params'

const typesToInclude = ['radio', 'checkbox', 'submit', 'file']

function formElementsForContainer (element) {
  let elements = []

  if ('elements' in element) {
    elements = element.elements
  } else {
    elements = element.querySelectorAll('input,select,textarea')
  }

  return elements
}

function nameFromInput (input) {
  if (input.hasAttribute('data-field-name-for-serialize')) {
    return input.dataset.fieldNameForSerialize
  } else {
    return input.name
  }
}

export function resetForm (element) {
  const elements = formElementsForContainer(element)

  each(elements, input => resetFormElement(input))
}

export function resetFormElement (input) {
  if (input.type === 'select') {
    if (input.options.length > 0) {
      input.options.selected = true
    }
  } else if (input.type !== 'button' && (input.checked || !typesToInclude.includes(input.type))) {
    input.value = ''
  }
}

function serializeFormValues (element, without = []) {
  const params = []
  const elements = formElementsForContainer(element)

  const addParam = (name, value) => {
    if (without.includes(name)) {
      return
    }

    params.push({
      name,
      value
    })
  }

  each(elements, input => {
    if (input.type === 'select') {
      each(input.options, option => {
        if (option.selected) {
          addParam(nameFromInput(input), option.value)
        }
      })
    } else if (input.type === 'hidden') {
      addParam(nameFromInput(input), input.value)
    } else if (input.type !== 'button' && (input.checked || !typesToInclude.includes(input.type))) {
      addParam(nameFromInput(input), input.value)
    }
  })

  return params
}

/*
  Turns a raw hash:
  {
    "name": "value",
    "form[name]": "value2"
  }

  Into a nested one for JSON submitting:

  {
    "name": "value",
    "form": {
      "name": "value2"
    }
  }
*/
function nestParams (obj) {
  const result = {}

  for (const key in obj) {
    const value = obj[key]
    const [param, nestedKey] = key.split('[')
    const isArray = key.toString().match(/\[\]$/) !== null

    if (nestedKey) {
      const cleanKey = nestedKey.slice(0, -1)
      const nestedValue = { [cleanKey]: value }

      if (isArray) {
        if (!result[param]) {
          result[param] = {}
        }

        if (!result[param][cleanKey]) {
          result[param][cleanKey] = []
        }

        result[param][cleanKey] = result[param][cleanKey].concat(...value)
      } else if (result[param]) {
        Object.assign(result[param], nestedValue)
      } else {
        result[param] = nestedValue
      }
    } else {
      result[key] = value
    }
  }

  return result
}

// returns object
export function serializeFormParams (element, options = {}) {
  const shouldNest = options.nest || false
  const shouldWrap = options.wrap || false

  const values = serializeFormValues(element, ['_method', 'authenticity_token'])
  const result = {}

  values.forEach(param => {
    if (result[param.name]) {
      if (isArray(result[param.name])) {
        result[param.name].push(param.value)
      } else {
        result[param.name] = [result[param.name], param.value]
      }
    } else {
      result[param.name] = param.value
    }
  })

  if (shouldWrap) {
    Object.assign(result, csrfParams())
  }

  if (shouldNest) {
    return nestParams(result)
  } else {
    return result
  }
}

// returns string form body
export function serializeForm (element, without = []) {
  const values = serializeFormValues(element, without)

  return map(values, param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`).join('&')
}
