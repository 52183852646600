// disconnects right away, since we only need this container to process streams
export default class TurboContainer extends HTMLElement {
  connectedCallback () {
    this.disconnect()
  }

  disconnect () {
    try { this.remove() } catch {}
  }
}
