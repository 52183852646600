import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['form', 'highlight', 'modal']

  hideModal (_event) {
    if (!this.hasModalOutlet) {
      return
    }

    this.modalOutlet.hide()
  }

  showModal (event) {
    this.modalOutlet.show(event)
  }

  showModalForm (event) {
    this.modalOutlet.showFormResult(event)
  }

  toggleMobileSidebar () {
    document.documentElement.classList.toggle('mobile-sidebar:is-open')
  }

  submitForm (event) {
    event.preventDefault()

    const link = event.target.closest('a[href]')

    if (!link) {
      return
    }

    this.formOutlet.url = link.getAttribute('href')
    this.formOutlet.submit()
  }

  submitToModal (event) {
    this.modalOutlet.submit(event)
  }

  updateHighlighted (event) {
    const link = event.target.closest('[data-menu-key]')

    if (!link) {
      return
    }

    if (!this.hasHighlightOutlet) {
      return
    }

    this.highlightOutlet.highlight(link.dataset.menuKey)
  }
}
