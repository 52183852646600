import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['form']

  submit (event) {
    if (!this.hasFormOutlet) {
      return
    }

    this.formOutlet.submit(event)
  }
}
