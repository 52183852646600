import { Controller } from '@hotwired/stimulus'
import { fetchJson } from 'util/fetch'
import { isNull } from 'lodash-es'
import { serializeFormParams } from 'util/form'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="builder-form"
export default class extends Controller {
  static outlets = ['builder-progress']

  static targets = ['submit']

  static values = {
    valid: Boolean
  }

  connect () {
    this.element.addEventListener('turbo:submit-start', this.submitted)
    this.element.addEventListener('turbo:submit-end', this.finishedSubmission)
    this.element.addEventListener('input', this.checkForFormValidity)

    this.checkForFormValidity()
  }

  disconnect () {
    this.element.removeEventListener('turbo:submit-start', this.submitted)
    this.element.removeEventListener('turbo:submit-end', this.finishedSubmission)
    this.element.removeEventListener('input', this.checkForFormValidity)
  }

  get formMethod () {
    const underscoredMethod = this.element.querySelector("input[name='_method']")

    if (!isNull(underscoredMethod)) {
      return underscoredMethod.value
    }

    return this.element.getAttribute('method')
  }

  get formUrl () {
    return this.element.getAttribute('action')
  }

  // connect the inverse to the builder progress controller back to this form
  builderProgressOutletConnected (outlet) {
    if (this.element.id) {
      outlet.element.dataset.builderProgressBuilderFormOutlet = `#${this.element.id}`
    }

    outlet.enableSubmit()
  }

  checkForFormValidity = event => {
    this.validValue = this.element.checkValidity()
  }

  finishedSubmission = event => {
    this.builderProgressOutlets.forEach(outlet => {
      outlet.enableSubmit()
    })
  }

  validValueChanged (isValid) {
    if (isValid) {
      this.builderProgressOutlets.forEach(outlet => {
        outlet.enableSubmit()
      })
    } else {
      this.builderProgressOutlets.forEach(outlet => {
        outlet.disableSubmit()
      })
    }
  }

  // from progress bar
  submit () {
    if (!this.hasSubmitTarget) {
      throw new Error('No submit target found')
    }

    this.element.requestSubmit(this.submitTarget)
  }

  async submitAndExit () {
    const params = serializeFormParams(this.element, {
      wrap: true, nest: true
    })

    this.submitted()

    const response = await fetchJson(this.formUrl, this.formMethod, params)

    if (!response) {
      return
    }

    if (!response.home_path) {
      return
    }

    Turbo.visit(response.home_path, {
      frame: '_top',
      action: 'advance'
    })

    this.finishedSubmission()
  }

  submitted = event => {
    this.builderProgressOutlets.forEach(outlet => {
      outlet.disableSubmit()
    })
  }
}
