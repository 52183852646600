let registered = false

export default async function (Quill) {
  if (registered) {
    return Quill
  }

  const icons = Quill.import('ui/icons')

  icons.header['2'] = '<span class="h-full text-sm flex items-center text-center font-medium">Lg</span>'
  icons.footer = '<span class="h-full text-sm flex items-center text-center font-medium">Sm</span>'
  icons.graf = '<span class="h-full text-sm flex items-center text-center font-medium">Md</span>'

  registered = true

  return null
}
