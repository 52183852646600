import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs"
export default class extends Controller {
  static classes = ['active']

  static targets = ['label', 'tab', 'panel']

  static values = {
    // to override the id for this tab set which is sent to events, change this value
    identifier: {
      type: String,
      default: 'main'
    },

    index: {
      type: Number,
      default: -1
    }
  }

  choose (event) {
    event.preventDefault()

    const tab = event.target.closest('[data-tabs-target="tab"]')

    if (!tab) {
      return
    }

    this.changeTabTo(this.tabTargets.indexOf(tab))
  }

  chooseSpecific (event) {
    event.preventDefault()

    const target = event.target.closest('[data-tab-index]')

    if (!target) {
      return
    }

    const index = parseInt(target.dataset.tabIndex)

    this.changeTabTo(index)
  }

  changeTabTo (index) {
    let foundATab = false

    this.tabTargets.forEach((tab, i) => {
      tab.classList.toggle(this.activeClass, i === index)

      if (i === index) {
        foundATab = true
      }
    })

    this.panelTargets.forEach((panel, i) => {
      panel.classList.toggle('hidden', i !== index)
    })

    if (foundATab) {
      this.indexValue = index
    } else {
      this.indexValue = undefined
    }
  }

  clear (event) {
    event.preventDefault()

    this.changeTabTo(-1)
  }

  // marks the current tab as active and de-selects others, but doesn't change any panels
  highlight (event) {
    const newTab = event.target.closest('[data-tabs-target="tab"]')

    this.tabTargets.forEach((tab, index) => {
      if (tab === newTab) {
        tab.classList.add(this.activeClass)
        this.indexValue = index
      } else {
        tab.classList.remove(this.activeClass)
      }
    })
  }

  indexValueChanged (newIndex, oldIndex) {
    if (oldIndex === undefined) {
      return
    }

    if (newIndex < 0) {
      this.#fireTabsEvent('cleared', { previousIndex: oldIndex, active: false, tabset: this.identifierValue })
      this.#fireTabsEvent('changed', { active: false, index: undefined, previousIndex: oldIndex, key: null, tabset: this.identifierValue })
      return
    }

    const tab = this.tabTargets[newIndex]
    const key = tab.dataset.key || tab.innerText

    this.#fireTabsEvent('changed', {
      active: (newIndex >= 0),
      index: newIndex,
      previousIndex: oldIndex,
      tabset: this.identifierValue,
      tab,
      key
    })

    if (this.hasLabelTarget) {
      this.labelTarget.innerText = tab.innerText
    }
  }

  #fireTabsEvent (name, details = {}) {
    this.element.dispatchEvent(new CustomEvent(`tab:${name}`, {
      bubbles: true,
      detail: details
    }))
  }
}
