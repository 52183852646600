import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="colorable"
export default class extends Controller {
  static targets = ['choice']

  connect () {
    this.choiceTargets.forEach(target => {
      if (target.dataset.colorValue) {
        target.style.backgroundColor = target.dataset.colorValue
      }
    })
  }

  adjustColorVar (cssVar, value) {
    this.element.style.setProperty(cssVar, value)
  }

  changeColor (event) {
    const target = event.target.closest('[data-action]')

    this.adjustColorVar(`--eg-${target.dataset.colorVar}`, target.dataset.colorValue)
  }
}
