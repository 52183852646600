let registered = false

export default async function (Quill) {
  if (registered) {
    return Quill
  }

  const Block = Quill.import('blots/block')

  // <p class="footer">
  class FooterFormat extends Block {
    static blotName = 'footer'
    static className = 'footer'

    static formats (node) {
      return node.classList.value
    }

    format (name, value) {
      super.format(name, value)
    }
  }

  Quill.register('formats/footer', FooterFormat)

  registered = true

  return null
}
