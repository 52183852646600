import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-section"
export default class extends Controller {
  static classes = ['hidden']

  static values = {
    current: String,
    field: String,
    value: {
      type: String,
      default: ''
    },
    observedValues: {
      type: Array,
      default: []
    }
  }

  initialize () {
    // default class names
    if (!this.hasHiddenClass) {
      this.element.dataset.formSectionHiddenClass = 'hidden'
    }
  }

  connect () {
    document.addEventListener('form-observer:field-change', this.fieldChange)
  }

  disconnect () {
    document.removeEventListener('form-observer:field-change', this.fieldChange)
  }

  fieldChange = ({ detail }) => {
    const { field, value } = detail

    if (this.fieldValue !== field) {
      return
    }

    this.currentValue = value
  }

  currentValueChanged (newValue, _oldValue) {
    if (this.observedValuesValue.includes(newValue)) {
      this.#enable()
    } else {
      this.#disable()
    }
  }

  valueValueChanged (newValue, _oldValue) {
    this.observedValuesValue = newValue.toString().split(',')
  }

  #disable () {
    this.element.classList.add(this.hiddenClass)
  }

  #enable () {
    this.element.classList.remove(this.hiddenClass)
  }
}
