import { Controller } from '@hotwired/stimulus'
import { fetchTurboStream } from 'util/fetch'

// Connects to data-controller="navigation-counter"
export default class extends Controller {
  static values = {
    loaded: Boolean,
    url: String
  }

  connect () {
    if (!this.loadedValue) {
      this.load()
    }
  }

  async load () {
    await fetchTurboStream(this.urlValue)

    this.loadedValue = true
  }
}
