import BlankState from './blank-state'
import TurboContainer from './turbo-container'
import Loading from './loading'
import Head from './head'
import SidebarSelection from './sidebar-selection'

const prefix = 'eg-'

// add elements here to initialize
const elements = {
  [`${prefix}blank-state`]: BlankState,
  [`${prefix}loading`]: Loading,
  [`${prefix}head`]: Head,
  [`${prefix}sidebar-selection`]: SidebarSelection,
  'turbo-container': TurboContainer
}

for (const key in elements) {
  if (!window.customElements.get(key)) {
    window.customElements.define(key, elements[key])
  }
}
