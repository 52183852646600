import { Controller } from '@hotwired/stimulus'
import { fadeAndRemove } from '../util/fadeAndRemove.js'

// Connects to data-controller="dismiss"
export default class extends Controller {
  static values = {
    delay: Number
  }

  connect () {
    if (this.hasDelayValue) {
      this.timeout = setTimeout(() => this.dismiss(), this.delayValue * 1000)
    }
  }

  dismiss (event = null) {
    if (event) {
      event.preventDefault()
    }

    window.requestAnimationFrame(() => {
      fadeAndRemove(this.element)
    })
  }
}
