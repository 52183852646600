// https://developers.google.com/identity/oauth2/web/guides/use-code-model#popup_or_redirect_mode

import { Controller } from '@hotwired/stimulus'
import { csrfParams } from 'util/params'
import { loadScript } from 'util/script'
import { fetchJson } from 'util/fetch'

const AUTHORIZATION_ENDPOINT = '/authorization/google'

// Connects to data-controller="google-authorization"
export default class extends Controller {
  static outlets = ['form', 'loading']

  static targets = ['authorizationKeyField', 'button', 'loading']

  static values = {
    clientId: String,
    scope: String,
    purpose: String,
    redirectUrl: String
  }

  async connect () {
    await loadScript('google-auth', 'https://accounts.google.com/gsi/client', 'google')

    const { google } = window

    if (!google) {
      return
    }

    this.client = google.accounts.oauth2.initCodeClient({
      client_id: this.clientIdValue,
      scope: this.scopes.join(' '),
      ux_mode: 'popup',
      callback: this.handleCodeResponse.bind(this)
    })

    this.loadingTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
  }

  authorize (event = null) {
    if (event) event.preventDefault()

    this.client.requestCode()
  }

  get scopes () {
    const result = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'openid'
    ]

    result.push(this.scopeValue)

    return result
  }

  async handleCodeResponse (response) {
    this.loadingTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')

    this.loadingOutlet.label = 'Verifying with Google ...'

    const authorizationResponse = await fetchJson(AUTHORIZATION_ENDPOINT, 'patch', csrfParams({
      code: response.code,
      scope: response.scope,
      purpose: this.purposeValue
    }))

    if (authorizationResponse.status === 'connected' && authorizationResponse.authorization) {
      this.authorizationKeyFieldTarget.value = authorizationResponse.authorization
      this.formOutlet.submit()
      return
    }

    this.loadingTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
  }
}
