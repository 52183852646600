import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-observer"

// Observers form input fields and dispatches a "field-change" event for
// each change to be picked up elsewhere.
export default class extends Controller {
  changed (event) {
    const input = event.target.closest('[data-action]')

    if (!input) {
      return
    }

    const field = input.name
    const value = input.value

    this.dispatch('field-change', {
      detail: {
        target: input,
        value,
        field,
        container: this.element
      }
    })
  }
}
