let registered = false

export default async function (Quill) {
  if (registered) {
    return Quill
  }

  const Inline = Quill.import('blots/block/embed')

  // <hr class="footer-line">
  class FooterLineFormat extends Inline {
    static blotName = 'footer-line'
    static className = 'footer-line'
    static tagName = 'hr'
  }

  Quill.register('formats/footer-line', FooterLineFormat)

  registered = true

  return null
}
