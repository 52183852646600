import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tiled-selection"
export default class extends Controller {
  static values = {
    dbid: String
  }

  selectOption () {
    const form = this.element.querySelector('button').form
    form.querySelector('select[hidden]').value = this.dbidValue

    form.querySelectorAll("[data-controller*='tiled-selection']").forEach(item => item.toggleAttribute('selected', false))
    this.element.toggleAttribute('selected', true)

    const submitButton = form.querySelector('input[type=submit][hidden]')
    if (submitButton) submitButton.click()
  }
}
