// width sources https://tailwindcss.com/docs/responsive-design

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}

export function currentBreakpoint () {
  const width = window.innerWidth

  if (width < breakpoints.sm) {
    return 'sm'
  } else if (width < breakpoints.md) {
    return 'md'
  } else if (width < breakpoints.lg) {
    return 'lg'
  } else if (width < breakpoints.xl) {
    return 'xl'
  } else {
    return '2xl'
  }
}

let currentObservedBreakpoint = null
let isObservingWindow = false
const observers = new Set()

const onBeforeVisit = () => disableBreakpointEvents()

export function observeBreakpoints (controller, value = 'breakpoint') {
  if (!isObservingWindow) {
    enableBreakpointEvents()
  }

  currentObservedBreakpoint = currentBreakpoint()
  controller[`${value}Value`] = currentObservedBreakpoint

  observers.add({ controller, value })
}

export function unobserveBreakpoints (controller) {
  observers.forEach((observer) => {
    if (observer.controller === controller) {
      observers.delete(observer)
    }
  })
}
//

function enableBreakpointEvents () {
  if (isObservingWindow) {
    return
  }

  isObservingWindow = true

  window.addEventListener('resize', onResizeWindow)
  document.addEventListener('turbo:before-visit', onBeforeVisit)
}

function disableBreakpointEvents () {
  if (!isObservingWindow) {
    return
  }

  window.removeEventListener('resize', onResizeWindow)
  document.removeEventListener('turbo:before-visit', onBeforeVisit)
  isObservingWindow = false
}

function onResizeWindow (event) {
  const newBreakpoint = currentBreakpoint()

  if (currentObservedBreakpoint === newBreakpoint) {
    return
  }

  const previousObservedBreakpoint = currentObservedBreakpoint
  currentObservedBreakpoint = newBreakpoint

  breakpointDidChange(currentObservedBreakpoint, previousObservedBreakpoint)
}

function breakpointDidChange (to, from) {
  observers.forEach(({ controller, value }) => {
    controller[`${value}Value`] = to
  })
}
