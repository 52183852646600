import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['option', 'master']

  connect () {
    this.saveCurrentValues() // Save initial values on connect
  }

  update () {
    this.saveCurrentValues() // Save current values before changing them
    const masterValue = this.masterTarget.value // Corrected to use masterTarget
    this.optionTargets.forEach((select) => {
      const optionsArray = [...select.options].map(option => option.value)
      if (optionsArray.includes(masterValue)) {
        select.value = masterValue
      }
    })
  }

  reset () {
    this.masterTarget.value = ''
    this.optionTargets.forEach((select, index) => {
      select.value = this.previousValues[index]
    })
  }

  saveCurrentValues () {
    this.previousValues = this.optionTargets.map(select => select.value)
  }
}
