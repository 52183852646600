import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-fieldset"

// for requiring at least one of a radio/checkbox group within a form
export default class extends Controller {
  static outlets = ['form']

  static targets = ['field']

  static values = {
    filled: Boolean,
    required: Boolean
  }

  connect () {
    this.checkFields()
  }

  checkFields () {
    let isChecked = false

    this.fieldTargets.forEach(field => {
      if (field.checked) {
        isChecked = true
      }
    })

    this.filledValue = isChecked
  }

  filledValueChanged (isReady) {
    this.formOutlet.validValue = isReady
  }
}
