import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="copy-field"
export default class extends Controller {
  connect () {
    this.element.addEventListener('focus', this.onFocus)
  }

  disconnect () {
    this.element.removeEventListener('focus', this.onFocus)
  }

  onFocus = event => {
    this.element.select()
  }
}
