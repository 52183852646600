import { Controller } from '@hotwired/stimulus'
import { observeBreakpoints, unobserveBreakpoints } from 'util/responsive'

// Connects to data-controller="responsive-frame"
export default class extends Controller {
  static values = {
    url: String,
    loaded: Boolean,
    breakpoint: String
  }

  connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    observeBreakpoints(this)
  }

  disconnect () {
    unobserveBreakpoints(this)
  }

  breakpointValueChanged (to) {
    if (to === '') {
      return
    }

    this.loadedValue = false
    this.load()
  }

  load () {
    if (!this.hasBreakpointValue) {
      return
    }

    this.loadedValue = true

    const divider = this.urlValue.includes('?') ? '&' : '?'

    this.element.src = `${this.urlValue}${divider}breakpoint=${this.breakpointValue}`
  }
}
