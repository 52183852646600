import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="background-color"
//
// used to change the background color of an element to a source target's background color
export default class extends Controller {
  static targets = ['source']

  sourceTargetConnected (target) {
    const color = target.dataset.backgroundColorValue

    if (!color) {
      this.element.style.backgroundColor = '#fff'
      return
    }

    const current = window.getComputedStyle(this.element).getPropertyValue('background-color')

    if (current === color) {
      return
    }

    this.element.style.backgroundColor = color
  }
}
