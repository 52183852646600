import { Controller } from '@hotwired/stimulus'
import { ResizeObserver } from '@juggle/resize-observer'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

function hrefFromEvent (event) {
  const target = event.target.closest('[data-action]')

  if (target.hasAttribute('data-url')) {
    return target.getAttribute('data-url')
  }

  const link = target.closest('a')

  if (link) {
    return link.getAttribute('href')
  }

  return null
}

function titleFromEvent (event) {
  const target = event.target.closest('[data-action]')

  if (target.hasAttribute('data-title')) {
    return target.getAttribute('data-title')
  }

  return target.innerText
}

export default class extends Controller {
  static targets = ['panel', 'backdrop', 'frame', 'action', 'title', 'header', 'footer', 'scroller', 'body']

  static outlets = ['transition']

  static values = {
    size: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'modal'
    },
    visible: Boolean
  }

  initialize () {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.#layoutModal()
    })
  }

  connect () {
    document.addEventListener('turbo:before-visit', this.beforeTurboVisit)
  }

  disconnect () {
    document.removeEventListener('turbo:before-visit', this.beforeTurboVisit)
  }

  beforeTurboVisit = event => {
    this.hide()
  }

  actionTargetConnected (element) {
    const action = element.dataset.modalAction

    switch (action) {
      case 'hide':
        this.hide()
        break

      case 'resize':
        this.sizeValue = element.dataset.size
        break
    }

    element.remove()
  }

  bodyTargetConnected (element) {
    this.resizeObserver.observe(element)
    this.resizeObserver.observe(document.body)
  }

  bodyTargetDisconnected (element) {
    this.resizeObserver.unobserve(element)
    this.resizeObserver.unobserve(document.body)
  }

  didHide (event) {
    this.element.classList.add('hidden')
    this.frameTarget.src = ''

    this.frameTarget.innerHTML = ''

    enableBodyScroll(this.frameTarget)
  }

  didShow () {
    disableBodyScroll(this.frameTarget)
  }

  footerTargetConnected (element) {
    this.resizeObserver.observe(element)
  }

  footerTargetDisconnected (element) {
    this.resizeObserver.unobserve(element)
  }

  headerTargetConnected (element) {
    this.resizeObserver.observe(element)
  }

  headerTargetDisconnected (element) {
    this.resizeObserver.unobserve(element)
  }

  async hide (event) {
    if (event) {
      if (event.target.dataset.modalContinue !== true) {
        event.preventDefault()
      }
    }

    if (this.visibleValue === false) {
      return
    }

    this.visibleValue = false

    await Promise.all(this.transitionOutlets.map(async outlet => {
      await outlet.hide(event)
    }))
  }

  async show (event) {
    if (this.visibleValue === true) {
      return
    }

    const href = hrefFromEvent(event)

    if (!href) {
      return
    }

    if (this.sizeValue !== 'default') {
      this.sizeValue = 'default'
    }

    const target = event.target.closest('[data-action]')

    if (target) {
      if ('modalSize' in target.dataset) {
        this.sizeValue = target.dataset.modalSize
      }
    }

    if (this.hasTitleTarget) {
      this.titleTarget.textContent = titleFromEvent(event)
    }

    this.frameTarget.innerHTML = `<eg-blank-state type="${this.typeValue}"></eg-blank-state>`

    this.frameTarget.src = href

    this.visibleValue = true
    this.element.classList.remove('hidden')

    await Promise.all(this.transitionOutlets.map(async outlet => {
      await outlet.toggle(event)
    }))

    // focus to allow ESC key to close
    this.panelTarget.focus()

    const formfield = this.panelTarget.querySelector('[data-form-target="primaryField"]')

    if (formfield && formfield.nodeName === 'INPUT') {
      formfield.focus()
    } else if (formfield && formfield.dataset.controller === 'rich-text-field') {
      const richTextController = this.application.getControllerForElementAndIdentifier(formfield, 'rich-text-field')

      if (richTextController) {
        richTextController.focus()
      }
    }

    this.didShow()
  }

  // same idea as show, but assumes a form was submitted with `event` so nothing is loaded
  // the form should be submitted with a data-turbo-frame="modal_contents"
  async showFormResult (event) {
    if (this.visibleValue === true) {
      return
    }

    if (this.sizeValue !== 'default') {
      this.sizeValue = 'default'
    }

    const target = event.target.closest('[data-action]')

    if (target) {
      if ('modalSize' in target.dataset) {
        this.sizeValue = target.dataset.modalSize
      }
    }

    this.frameTarget.innerHTML = `<eg-blank-state type="${this.typeValue}"></eg-blank-state>`

    this.visibleValue = true
    this.element.classList.remove('hidden')

    await Promise.all(this.transitionOutlets.map(async outlet => {
      await outlet.toggle(event)
    }))

    // focus to allow ESC key to close
    this.panelTarget.focus()

    const formfield = this.panelTarget.querySelector('[data-form-target="primaryField"]')

    if (formfield) {
      formfield.focus()
    }

    this.didShow()
  }

  sizeValueChanged (newValue, oldValue) {
    if (typeof oldValue !== 'undefined') {
      this.element.classList.remove(`modal--size-${oldValue}`)
    }

    this.element.classList.add(`modal--size-${newValue}`)
  }

  submit (event) {
    if (this.hasTitleTarget) {
      this.titleTarget.textContent = titleFromEvent(event)
    }

    if (this.sizeValue !== 'default') {
      this.sizeValue = 'default'
    }

    const target = event.target.closest('[data-action]')

    if (target) {
      if ('modalSize' in target.dataset) {
        this.sizeValue = target.dataset.modalSize
      }
    }

    this.frameTarget.innerHTML = `<eg-blank-state type="${this.typeValue}"></eg-blank-state>`

    this.visibleValue = true
    this.element.classList.remove('hidden')

    this.transitionOutlets.forEach(outlet => outlet.toggle(event))
  }

  #layoutModal () {
    // if the body and footer targets are not present, we can't layout the modal
    if (this.hasBodyTarget !== true || this.hasFooterTarget !== true) {
      return
    }

    const footer = this.footerTarget

    const headerHeights = this.headerTargets.reduce((result, header) => {
      return result + header.getBoundingClientRect().height
    }, 0)

    const windowHeight = window.innerHeight
    const footerHeight = footer.getBoundingClientRect().height

    const gutter = 48 + 24 + 12

    let bodyHeight = windowHeight - footerHeight - headerHeights - (gutter * 2)

    // min-height
    if (bodyHeight <= 100) {
      bodyHeight = 100
    }

    this.bodyTarget.style.height = `${bodyHeight}px`
    this.bodyTarget.style.overflow = 'auto'
  }
}
