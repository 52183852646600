function getAncestorsForConstructor (constructor) {
  const ancestors = []
  while (constructor) {
    ancestors.push(constructor)
    constructor = Object.getPrototypeOf(constructor)
  }
  return ancestors.reverse()
}

export function readInheritableStaticValue (constructor, propertyName) {
  const ancestors = getAncestorsForConstructor(constructor)
  return ancestors.reduce((result, constructor) => {
    return constructor[propertyName]
  }, null)
}
