import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-options-kinds"

// lets additional options be presented depending on what "kind" is selected
export default class extends Controller {
  selectKind (event) {
    const selectControl = event.target

    if (selectControl.dataset.kindsWithOptions.split(',').includes(selectControl.value)) {
      this.nestedFieldset.hidden = false
    } else {
      this.nestedFieldset.hidden = true
    }
  }

  add (event) {
    const firstHidden = this.nestedFieldset.querySelector('div[hidden]')
    if (firstHidden) firstHidden.hidden = false

    // if there are no more hidden options, we'll remove the add button
    if (!this.nestedFieldset.querySelector('div[hidden]')) {
      event.currentTarget.remove()
    }
  }

  // the nested fieldset holds the option fields
  get nestedFieldset () {
    return this.element.querySelector('fieldset')
  }
}
