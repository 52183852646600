const loadedScripts = {}

export function loadScript (id, src, global) {
  return new Promise((resolve, reject) => {
    if (loadedScripts[id] === true) {
      resolve(global ? window[global] : undefined)
      return
    }

    const s = document.createElement('script')
    s.src = src
    s.onload = () => {
      resolve(global ? window[global] : undefined)
    }
    s.onerror = reject
    document.body.appendChild(s)

    loadedScripts[id] = true
  })
}
