import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-view-collection"
export default class extends Controller {
  static targets = ['item']

  static values = {
    ids: Array
  }

  add (id, templateNode) {
    const existed = this.#removeItemsWithId(id)
    this.#appendItemWithTemplate(id, templateNode)

    if (existed) {
      return
    }

    this.dispatch('add', { detail: { id, source: 'direct' } })
    this.idsValue = [...this.idsValue, id]
  }

  idsValueChanged (newValue) {
    if (newValue.length === 0) {
      this.element.classList.add('hidden')

      document.querySelectorAll('[data-table-view-collection-toggle]').forEach(element => {
        element.classList.remove('hidden')
      })
    } else {
      this.element.classList.remove('hidden')

      document.querySelectorAll('[data-table-view-collection-toggle]').forEach(element => {
        element.classList.add('hidden')
      })
    }
  }

  isSelected (id) {
    return this.idsValue.includes(id)
  }

  remove (id) {
    this.#removeItemsWithId(id)

    this.dispatch('remove', { detail: { id, source: 'direct' } })
    this.idsValue = this.idsValue.filter(i => i !== id)
  }

  removeElement (event) {
    const element = event.target.closest('[data-identifier]')

    if (!element) {
      return
    }

    const id = element.dataset.identifier

    this.#removeItemsWithId(id)

    this.dispatch('remove', { detail: { id, source: 'event' } })
    this.idsValue = this.idsValue.filter(i => i !== id)
  }

  #appendItemWithTemplate (id, templateNode) {
    const clone = templateNode.content.cloneNode(true)

    const item = document.createElement('div')
    item.dataset.identifier = id
    item.dataset.tableViewCollectionTarget = 'item'
    item.appendChild(clone)

    this.element.appendChild(item)
  }

  #removeItemsWithId (id) {
    let removed = false

    this.itemTargets.forEach(item => {
      if (item.dataset.identifier === id) {
        item.remove()
        removed = true
      }
    })

    return removed
  }
}
