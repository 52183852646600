import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="clear-results-with-external-click"
export default class extends Controller {
  static values = {
    // The container element to attach the click listener to
    clickable: String,
    // CSS selector for elements to exclude from click clearing
    exclude: String,
    // CSS selector for input elements to clear
    target: String
  }

  connect () {
    // Check if we have a target value
    if (!this.targetValue) {
      console.warn('Clearable controller: No target value specified. The controller needs a target value to know which elements to clear.')
      return
    }

    // Store the inputs we'll be clearing
    this.clearableInputs = this.element.querySelectorAll(this.targetValue)

    // Warn if no matching elements found
    if (this.clearableInputs.length === 0) {
      console.warn(`Clearable controller: No elements found matching selector "${this.targetValue}"`)
    }

    // Get the container element, default to document.body if not specified
    this.clickableElement = this.clickableValue
      ? document.querySelector(this.clickableValue)
      : document.body

    // Bind the click handler to maintain correct 'this' context
    this.boundHandleClick = this.handleClick.bind(this)

    // Add click listener to the container
    this.clickableElement.addEventListener('click', this.boundHandleClick)
  }

  disconnect () {
    // Remove the click listener from the container
    this.clickableElement.removeEventListener('click', this.boundHandleClick)
  }

  handleClick (event) {
    // Check if any inputs have values - if not, return early
    const hasValues = Array.from(this.clearableInputs).some(input => input.value.trim() !== '')
    if (!hasValues) { return }

    // Check if click target is within excluded area
    if (this.excludeValue) {
      const excludedElements = this.element.querySelectorAll(this.excludeValue)

      for (const excludedElement of excludedElements) {
        if (excludedElement.contains(event.target)) {
          return
        }
      }
    }

    // If click is outside the form and outside excluded areas, clear inputs
    if (!this.element.contains(event.target)) {
      this.clearableInputs.forEach(input => {
        input.value = ''
        // Dispatch input event to trigger any listeners
        input.dispatchEvent(new Event('input', { bubbles: true }))
      })
    }
  }
}
