import { VueController } from 'util/vue-controller'

// Connects to data-controller="toggle-switch"
export default class extends VueController {
  static targets = ['checkbox']

  static values = {
    isChecked: Boolean,
    name: String,
    checkedValue: {
      type: String,
      default: '1'
    },
    uncheckedValue: {
      type: String,
      default: '0'
    }
  }

  static vueComponent = 'toggle-switch'

  async connect () {
    this.isCheckedValue = !!this.checkbox.checked
    this.nameValue = this.checkbox.name
    this.checkedValueValue = this.checkbox.value

    await this.loadVueComponent()
  }

  get checkbox () {
    if (this.hasCheckboxTarget) {
      return this.checkboxTarget
    } else {
      throw new Error('no checkbox found')
    }
  }

  get props () {
    return {
      checked: this.checkbox.checked,
      name: this.nameValue,
      checkedValue: this.checkedValueValue,
      uncheckedValue: this.uncheckedValueValue
    }
  }
}
