import { Controller } from '@hotwired/stimulus'
import { fetchTurboStream } from 'util/fetch'
import { csrfParams } from 'util/params'

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = ['item']

  static values = {
    url: String
  }

  async connect () {
    const { Sortable } = await import('sortablejs')

    this.sortable = new Sortable(this.element, {
      handle: '[data-sortable-target="handle"]',
      animation: 250,
      onEnd: this.onChangeSort
    })
  }

  disconnect () {
    this.sortable.destroy()
  }

  get ids () {
    return this.itemTargets.map((item, index) => {
      return item.dataset.id
    })
  }

  onChangeSort = event => {
    if (!this.hasUrlValue) {
      return
    }

    fetchTurboStream(this.urlValue, 'post', csrfParams({
      ids: this.ids
    }))
  }
}
