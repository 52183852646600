import { Controller } from '@hotwired/stimulus'
import { getConfig } from 'util/config'

// Connects to data-controller="navigation-item"
export default class extends Controller {
  static values = {
    permissions: Array,
    default: []
  }

  connect () {
    if (this.hasPermissionsValue) {
      this.#checkPermissions()
    }
  }

  #checkPermissions () {
    const myPermissions = new Set(getConfig('permissions'))

    if (myPermissions.has('all:write')) {
      return this.show()
    }

    const itemPermissions = this.permissionsValue

    if (itemPermissions.length === 0) {
      return this.show()
    }

    for (const value of itemPermissions) {
      if (myPermissions.has(value)) {
        return this.show()
      }
    }

    return this.hide()
  }

  hide () {
    this.element.remove()
  }

  show () {
    this.element.classList.remove('hidden')
    this.element.removeAttribute('aria-hidden')
  }
}
