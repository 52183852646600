import { Controller } from '@hotwired/stimulus'
import { csrfParams } from 'util/params'
import { fetchTurboStream } from 'util/fetch'

// Connects to data-controller="resize"
export default class extends Controller {
  static values = {
    width: Number,
    height: Number,

    gridSize: {
      type: Number,
      default: 10
    },

    updateUrl: String,
    updateParam: String
  }

  async connect () {
    const InteractJs = await import('interactjs')
    const interact = InteractJs.default

    const grid = interact.snappers.grid({
      x: this.gridSizeValue,
      y: this.gridSizeValue
    })

    this.interaction = interact(this.element)
      .resizable({
        edges: {
          top: false,
          left: false,
          bottom: true,
          right: true
        },
        listeners: {
          move: this.#onMove,
          end: this.#onEnd
        },
        modifiers: [
          interact.modifiers.snap({
            targets: [grid]
          }),

          interact.modifiers.snapSize({
            targets: [
              { width: this.gridSizeValue },
              interact.snappers.grid({ width: this.gridSizeValue, height: this.gridSizeValue })
            ]
          }),

          interact.modifiers.restrict({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
  }

  disconnect () {
    if (this.interaction) {
      this.interaction.unset()
      this.interaction = null
    }
  }

  save () {
    if (!this.hasUpdateUrlValue) {
      return
    }

    const param = this.updateParamValue || 'dimensions'

    // round to nearest multiple of 5
    this.widthValue = Math.round(this.widthValue / 5) * 5
    this.heightValue = Math.round(this.heightValue / 5) * 5

    const params = csrfParams({
      [`${param}[width]`]: this.widthValue,
      [`${param}[height]`]: this.heightValue
    })

    fetchTurboStream(this.updateUrlValue, 'patch', params)
  }

  #onEnd = event => {
    this.save()
  }

  #onMove = event => {
    Object.assign(this.element.style, {
      width: `${event.rect.width}px`,
      height: `${event.rect.height}px`
    })

    this.heightValue = event.rect.height
    this.widthValue = event.rect.width
  }
}
