import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="analytics-chart"
export default class extends Controller {
  static values = {
    options: String
  }

  async connect () {
    const options = JSON.parse(atob(this.optionsValue))

    const { default: ApexCharts } = await import('apexcharts')

    if (!options) {
      return
    }

    if (options?.xaxis?.labels?.ellipsis ?? false) {
      options.xaxis.labels = {
        formatter: function (val) {
          return val.length > 20 ? `${val.slice(0, 20).trim()}...` : val
        }
      }
    }

    const yAxisFormat = options?.yaxis?.__format ?? 'plain'

    switch (yAxisFormat) {
      case 'percentage':
        options.yaxis.labels = {
          formatter: (value) => `${value}%`
        }
        break

      case 'numeric':
        options.yaxis.labels = {
          formatter: (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        break

      default:
        break
    }

    const chartOptions = Object.assign({}, options.chart, {
      fontFamily: 'EGGraphik,Helvetica,sans-serif',
      foreColor: '#77726D'
    })

    options.chart = chartOptions

    const tooltip = Object.assign({}, options.tooltip, {
      style: {
        fontSize: '14px',
        fontFamily: options.chart.fontFamily
      }
    })

    options.tooltip = tooltip

    const chart = new ApexCharts(this.element, options)

    chart.render()
  }
}
