import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="table-view"
export default class extends Controller {
  static classes = ['activeRow']

  static outlets = ['table-view-collection']

  static targets = ['checkbox', 'row']

  static values = {
    collecting: Boolean
  }

  connect () {
    // shortcut to adding a few things manually each time
    window.addEventListener('turbo:before-cache', this.turboCache)
    window.addEventListener('keydown', this.keyDown)
    window.addEventListener('keyup', this.keyUp)

    window.addEventListener('table-view-collection:remove', this.onCollectionRemove)

    window.addEventListener('bulk-select:checked', this.didToggleCheckbox)
  }

  disconnect () {
    window.removeEventListener('turbo:before-cache', this.turboCache)
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('keyup', this.keyUp)

    window.removeEventListener('table-view-collection:remove', this.onCollectionRemove)

    window.removeEventListener('bulk-select:checked', this.didToggleCheckbox)
  }

  didToggleCheckbox = event => this.#checkboxToggledExternally(event)
  keyDown = event => this.#enableShiftSelect(event)
  keyUp = event => this.#disableShiftSelect(event)
  turboCache = event => this.reset(event)

  onCollectionRemove = event => this.unhighlight(event.detail.id)

  get isCollecting () {
    return this.hasTableViewCollectionOutlet && this.collectingValue
  }

  rowTargetConnected (row) {
    if (!this.isCollecting) {
      return
    }

    const isAlreadySelected = this.tableViewCollectionOutlet.isSelected(row.dataset.identifier)

    if (!isAlreadySelected) {
      return
    }

    row.classList.add(this.activeRowClass)
    row.querySelector('input[type="checkbox"]').checked = true
  }

  // called if a row's checkbox is changed
  check (event) {
    const checkbox = event.target.closest('input[type="checkbox"]')
    const row = checkbox.closest('[data-table-view-target~="row"]')

    if (this.isShifting && this.lastCheckedCheckbox) {
      this.#shiftSelect(this.lastCheckedCheckbox, event.target)
    }

    this.lastCheckedCheckbox = event.target

    if (checkbox.checked) {
      row.classList.add(this.activeRowClass)

      this.#collectRow(row)
    } else {
      row.classList.remove(this.activeRowClass)

      this.#uncollectRow(row)
    }
  }

  reset (event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    this.rowTargets.forEach(row => {
      row.classList.remove(this.activeRowClass)
    })
  }

  unhighlight (identifier) {
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.value === identifier) {
        const row = checkbox.closest('[data-table-view-target~="row"]')
        row.classList.remove(this.activeRowClass)

        checkbox.checked = false
      }
    })
  }

  #checkboxToggledExternally (event) {
    this.checkboxTargets.forEach(checkbox => {
      const row = checkbox.closest('[data-table-view-target~="row"]')

      if (checkbox.checked) {
        row.classList.add(this.activeRowClass)
        this.#collectRow(row)
      } else {
        row.classList.remove(this.activeRowClass)
        this.#uncollectRow(row)
      }
    })
  }

  #collectRow (row) {
    if (!this.isCollecting) {
      return
    }

    const template = row.querySelector('template[data-purpose~="collection"]')

    if (!template) {
      return
    }

    this.tableViewCollectionOutlet.add(row.dataset.identifier, template)
  }

  #disableShiftSelect (event) {
    if (event.key === 'Shift') {
      this.isShifting = false
    }
  }

  #enableShiftSelect (event) {
    if (event.key === 'Shift') {
      this.isShifting = true
    }
  }

  #shiftSelect (from, to) {
    const start = this.checkboxTargets.indexOf(from)
    const end = this.checkboxTargets.indexOf(to)

    this.checkboxTargets.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(checkbox => {
      checkbox.checked = true

      const row = checkbox.closest('[data-table-view-target~="row"]')
      row.classList.add(this.activeRowClass)
      this.#collectRow(row)
    })
  }

  #uncollectRow (row) {
    if (!this.isCollecting) {
      return
    }

    this.tableViewCollectionOutlet.remove(row.dataset.identifier)
  }
}
