import { Controller } from '@hotwired/stimulus'
import { fetchTurboStream } from 'util/fetch'

// Connects to data-controller="paginate"
export default class extends Controller {
  static values = {
    currentPage: {
      default: 1,
      type: Number
    },
    url: String
  }

  static targets = ['nextPageZone']

  initialize () {
    this.intersectionObserver = new window.IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.nextPage()
        }
      })
    }, {
      rootMargin: '150px',
      threshold: 1
    })
  }

  nextPageZoneTargetConnected (target) {
    this.intersectionObserver.observe(target)
  }

  nextPageZoneTargetDisconnected (target) {
    this.intersectionObserver.unobserve(target)
  }

  get nextPageNumber () {
    return this.currentPageValue + 1
  }

  get url () {
    if (!this._url) {
      // use static host for relative links
      this._url = new URL(this.urlValue, 'https://electragram.com')
    }

    return this._url
  }

  async nextPage () {
    if (this.isFetching) {
      return
    }

    this.isFetching = true

    this.url.searchParams.set('page', this.nextPageNumber)

    const nextUrl = this.url.toString().replace('https://electragram.com', '')

    await fetchTurboStream(nextUrl, 'get')

    this.currentPageValue++

    this.isFetching = false
  }
}
