import { Controller } from '@hotwired/stimulus'
import { delay } from 'util/delay'

// Connects to data-controller="builder-input"
export default class extends Controller {
  static targets = ['actions']

  static outlets = ['builder-field', 'tabs']

  static values = {
    actionsTemplate: String,
    layerId: String,

    focused: Boolean
  }

  connect () {
    if (!this.hasActionsTarget) {
      this.#prepareActions()
    }
  }

  disconnect () {
    if (this.hasActionsTarget) {
      this.actionsTarget.remove()
    }
  }

  async blurred (_event) {
    this.focusedValue = false

    await delay(150)

    if (!this.focusedValue) {
      this.element.style.removeProperty('--eg-builder-border')
      this.element.style.removeProperty('--eg-builder-background')
      this.element.style.removeProperty('--eg-builder-actions-display')
    }
  }

  async focusOnInput (event) {
    if (this.hasTabsOutlet) {
      this.tabsOutlet.changeTabTo(0)
    }

    const field = document.getElementById(`content_set_${this.layerIdValue}`)

    if (!field) {
      return
    }

    if (window.innerWidth < 900) {
      await delay(250)
    }

    if (field.dataset.richTextFieldTarget === 'input') {
      const richTextController = field.closest('[data-controller="rich-text-field"]')
      const controller = this.application.getControllerForElementAndIdentifier(richTextController, 'rich-text-field')

      if (controller) {
        controller.focus()
      }
    } else {
      field.focus()
    }
  }

  hide () {
    this.element.style.display = 'none'

    this.builderFieldOutlets.forEach(field => {
      field.disable()
    })
  }

  hovered (event) {
    this.focusedValue = true

    this.element.style.setProperty('--eg-builder-border', 'var(--eg-color-bright-accent, red)')
    this.element.style.setProperty('--eg-builder-background', 'rgba(255, 255, 255, 0.3)')
    this.element.style.setProperty('--eg-builder-actions-display', 'flex')
  }

  #prepareActions () {
    const template = document.querySelector(this.actionsTemplateValue)

    if (!template) {
      return
    }

    const actions = document.importNode(template.content, true)

    this.element.appendChild(actions)
  }
}
