import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['transition']

  toggle (event) {
    if (!this.hasTransitionOutlet) {
      return
    }

    this.transitionOutlet.toggle(event)
  }
}
