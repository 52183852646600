// app/javascript/controllers/phone_prefix_controller.js
import { Controller } from '@hotwired/stimulus'
import { parsePhoneNumberWithError, AsYouType } from 'libphonenumber-js'

export default class extends Controller {
  static targets = ['countryCodePrefix', 'phoneNumber']

  connect () {
    this.phoneNumberTarget.addEventListener('input', this.formatPhoneInput.bind(this))
    this.countryCodePrefixTarget.addEventListener('input', this.countryCodePrefixChanged.bind(this))

    this.formatPhoneInput()
  }

  disconnect () {
    this.phoneNumberTarget.removeEventListener('input', this.formatPhoneInput.bind(this))
    this.countryCodePrefixTarget.removeEventListener('input', this.countryCodePrefixChanged.bind(this))
  }

  formatPhoneInput (_event) {
    this.formatNumberInput(this.phoneNumberTarget)
  }

  countryCodePrefixChanged () {
    if (this.phoneNumberTarget.value) {
      this.formatNumberInput(this.phoneNumberTarget)
    }
  }

  formatNumberInput (inputElement) {
    const countryCodePrefix = this.countryCodePrefixTarget.value.trim()
    const inputValue = inputElement.value.trim()

    // Remove error class if input is empty
    if (!inputValue) {
      inputElement.setCustomValidity('')
      inputElement.classList.remove('with-error')

      return
    }

    if (!countryCodePrefix) return

    const validationErrorMessage = `Please enter a valid phone number for the country code you provided: +${countryCodePrefix}`

    try {
      const fullNumber = `+${countryCodePrefix}${inputValue}`
      const parsedNumber = parsePhoneNumberWithError(fullNumber)
      const formatter = new AsYouType(parsedNumber.country)
      formatter.input(fullNumber)

      if (parsedNumber.isValid()) {
        inputElement.setCustomValidity('')
        inputElement.classList.remove('with-error')
        const internationalNumber = parsedNumber.formatInternational()

        // Remove country code prefix from the input
        const formattedNumber = internationalNumber
          .replace(`+${countryCodePrefix} `, '')
          .replaceAll(' ', '-')

        inputElement.value = formattedNumber
      } else {
        inputElement.setCustomValidity(validationErrorMessage)
        inputElement.classList.add('with-error')
      }
    } catch (error) {
      if (inputValue) {
        inputElement.setCustomValidity(validationErrorMessage)
        inputElement.classList.add('with-error')
      }
    }
  }
}
