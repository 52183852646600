import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="builder-split"
export default class extends Controller {
  static classes = ['active']

  static values = {
    isActive: Boolean
  }

  connect () {
    this.element.addEventListener('tab:changed', this.onTabChanged)
  }

  disconnect () {
    this.element.removeEventListener('tab:changed', this.onTabChanged)
  }

  onTabChanged = event => {
    const { active } = event.detail

    this.isActiveValue = active === true
  }

  isActiveValueChanged () {
    if (this.isActiveValue) {
      this.element.classList.add(this.activeClass)
    } else {
      this.element.classList.remove(this.activeClass)
    }
  }
}
