const config = {
  account: null,
  user: null,
  permissions: []
}

export function getConfig (key) {
  if (config[key] === undefined) {
    return null
  }

  return config[key]
}

export function setConfig (key, value) {
  config[key] = value
  return value
}
