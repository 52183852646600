import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

// Connects to data-controller="static-search"
export default class extends Controller {
  static targets = ['input', 'item']

  connect () {
    this.searchInput = debounce((event) => {
      this.search(event)
    }, 100)

    this.reset()
  }

  reset (event) {
    this.inputTarget.value = ''
    this.inputTarget.focus()

    this.itemTargets.forEach((item) => {
      item.classList.remove('hidden')
    })
  }

  search (event) {
    const query = this.inputTarget.value.toLowerCase().replace(/[^a-z0-9]/g, '')

    if (query.length === 0) {
      this.itemTargets.forEach((item) => {
        item.classList.remove('hidden')
      })

      return
    }

    this.itemTargets.forEach((item) => {
      const value = item.dataset.value.toString()

      if (value.includes(query)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }
}
