import { Controller } from '@hotwired/stimulus'

const removeTemplate = `<button type="button" data-rich-text-attachment-target="remove" data-action="rich-text-attachment#remove" class="absolute -top-5 left-1/2 transform -translate-x-1/2 size-10 rounded-full text-gray-600 bg-white border-2 border-white shadow flex items-center justify-center hover:bg-highlight hover:border-accent transition-colors duration-200 hover:text-accent">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
    </svg>
  </button>`

// Connects to data-controller="rich-text-attachment"
export default class extends Controller {
  static targets = ['remove']

  async connect () {
    const quill = await import('quill')
    this.Quill = quill.default

    this.#setupUI()
  }

  get blot () {
    return this.Quill.find(this.element, true)
  }

  remove (event) {
    event.preventDefault()

    this.blot.removeAttachment()
  }

  #setupUI () {
    if (!this.hasRemoveTarget) {
      this.element.insertAdjacentHTML('beforeend', removeTemplate)
    }
  }
}
