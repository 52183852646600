import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-repeatable-fields"

// lets new fields in an array group be added via a supplied `<template>`, as
// well as existing fields removed
export default class extends Controller {
  static targets = ['template', 'adder']

  add () {
    this.adderTarget.insertAdjacentElement('beforebegin', this.clonedTemplate())
  }

  remove (event) {
    event.target.closest('fieldset').remove()
  }

  clonedTemplate () {
    return this.templateTarget.content.firstElementChild.cloneNode(true)
  }
}
