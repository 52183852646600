import { Controller } from '@hotwired/stimulus'
import { delay } from 'util/delay'

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ['modalField', 'primaryField', 'submit']

  static outlets = ['modal']

  static values = {
    disabled: Boolean,
    confirmation: String,
    valid: {
      type: Boolean,
      default: true
    }
  }

  async connect () {
    this.element.addEventListener('turbo:submit-start', this.onSubmitStart)
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd)
    this.element.addEventListener('direct-uploads:start', this.onSubmitStart)

    await this.#focusOnField()
  }

  disconnect () {
    this.element.removeEventListener('turbo:submit-start', this.onSubmitStart)
    this.element.removeEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  get form () {
    if (this.element.nodeName === 'FORM') {
      return this.element
    } else {
      return this.element.closest('form')
    }
  }

  get submitButton () {
    if (this.hasSubmitTarget) {
      return this.submitTarget
    }

    const { form } = this

    if (!form) {
      return null
    }

    let submit = form.querySelector('input[type=submit]')

    if (submit) {
      return submit
    }

    submit = form.querySelector('button[type=submit]')

    if (submit) {
      return submit
    }

    return null
  }

  get url () {
    return this.element.getAttribute('action')
  }

  set url (value) {
    this.element.setAttribute('action', value)
  }

  onSubmitEnd = event => {
    this.enableButton()

    if (event.detail.success !== true) {
      return
    }

    if (this.hasModalOutlet) {
      this.modalOutlet.hide()
    }
  }

  onSubmitStart = event => {
    this.disableButton()

    this.dispatch('submitted')
  }

  modalFieldTargetConnected () {
    const isWithinModal = !!this.element.closest('.modal')

    if (isWithinModal) {
      this.modalFieldTarget.value = 'modal'
    } else {
      this.modalFieldTarget.value = 'page'
    }
  }

  // enable/disable the form based on values API
  disabledValueChanged (disabled) {
    if (disabled) {
      this.disableButton()
    } else {
      this.enableButton()
    }
  }

  validValueChanged (formIsValid) {
    if (formIsValid === true) {
      this.enableButton()
    } else {
      this.disableButton()
    }
  }

  confirm (event) {
    if (!this.hasConfirmationValue) {
      return
    }

    if (!window.confirm(this.confirmationValue)) {
      event.preventDefault()
    }
  }

  disableButton () {
    const { submitButton } = this

    if (submitButton) {
      submitButton.setAttribute('disabled', 'disabled')
    }
  }

  enableButton () {
    const { submitButton } = this

    if (submitButton) {
      submitButton.removeAttribute('disabled')
    }
  }

  submit (event = null) {
    if (event) {
      event.preventDefault()
    }

    this.form.submit()
  }

  async #focusOnField () {
    if (!this.hasPrimaryFieldTarget) {
      return
    }

    this.primaryFieldTarget.focus()

    // give modals and forms a chance to open
    await delay(250)

    this.dispatch('focus')
  }
}
