import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="refresh"
export default class extends Controller {
  static targets = ['frame']

  static values = {
    interval: {
      type: Number,
      default: 2500
    },

    url: String
  }

  connect () {
    if (document.documentElement.hasAttribute('data-turbo-preview')) {
      return
    }

    if (!this.hasUrlValue) {
      return
    }

    this.timer = setInterval(() => this.update(), this.intervalValue)
  }

  disconnect () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  get target () {
    if (this.hasFrameTarget) {
      return this.frameTarget
    } else {
      return this.element
    }
  }

  update () {
    this.target.src = this.urlValue
  }
}
