export default class SidebarSelection extends HTMLElement {
  connectedCallback () {
    document.documentElement.querySelectorAll("[data-controller~='sidebar']").forEach(controller => {
      controller.dataset.sidebarCurrentItemValue = this.identifier
    })

    this.disconnect()
  }

  get identifier () {
    return this.getAttribute('identifier')
  }

  disconnect () {
    try { this.remove() } catch {}
  }
}
