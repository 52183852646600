import { Controller } from '@hotwired/stimulus'
import morphdom from 'morphdom'

export default class extends Controller {
  connect () {
    document.addEventListener('turbo:before-frame-render', this.handleTurboFrameRender.bind(this))
  }

  disconnect () {
    document.removeEventListener('turbo:before-render', this.handleTurboFrameRender.bind(this))
  }

  handleTurboFrameRender (event) {
    const container = this.element
    const activeElement = document.activeElement // the element we want to keep focus on
    const target = event.target // the frame that is being rendered

    if (!target) { return }
    if (!container.contains(target)) { return }

    event.detail.render = (currentElement, newElement) => {
      // Use morphdom to update the DOM, skipping the focused element
      morphdom(currentElement, newElement, {
        childrenOnly: true,
        onBeforeElUpdated: (fromEl, _toEl) => {
          // check if the element being updated is the focused element
          if (fromEl === activeElement) {
            return false // Prevent morphdom from updating the focused element
          }
          return true
        }
      })
    }
  }
}
