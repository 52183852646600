import { Application } from '@hotwired/stimulus'
import { StreamActions } from '@hotwired/turbo'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = Application

// allow Turbo stream action to redirect to a given
StreamActions.redirect = function () {
  Turbo.visit(this.getAttribute('url'))
}

export { application }
