import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="request-mode"
export default class extends Controller {
  static values = {
    mode: String
  }

  connect () {
    document.addEventListener('turbo:before-fetch-request', this.requestDidSubmit)
  }

  disconnect () {
    document.removeEventListener('turbo:before-fetch-request', this.requestDidSubmit)
  }

  requestDidSubmit = event => {
    const controller = event.target.closest("[data-controller~='request-mode']")

    if (controller === this.element) {
      event.detail.fetchOptions.headers['X-Eg-Request-Mode'] = this.modeValue
    }
  }
}
