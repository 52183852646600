import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['active']

  static values = {
    menu: String
  }

  connect () {
    this.highlightCurrent()
  }

  highlightCurrent () {
    // remove existing
    this.element.querySelectorAll(`.${this.activeClass}`).forEach(element => element.classList.remove(this.activeClass))

    const menus = this.menuValue.toString().split(' ')

    menus.forEach(menu => {
      const activeItem = this.element.querySelector(`[data-navigation-item="${menu}"]`)

      if (!activeItem) {
        return
      }

      activeItem.classList.add(this.activeClass)

      if (activeItem.nodeName === 'DETAILS') {
        activeItem.open = true
      }
    })
  }
}
