import { Controller } from '@hotwired/stimulus'
import { loadScript } from 'util/script'
import { fetchJson } from 'util/fetch'
import { csrfParams } from 'util/params'

// Connects to data-controller="google-sign-in"
export default class extends Controller {
  static targets = ['button']

  static values = {
    clientId: String,
    verifyRoute: String
  }

  async connect () {
    await loadScript('google-auth', 'https://accounts.google.com/gsi/client', 'google')

    const { google } = window

    if (!google) {
      return
    }

    google.accounts.id.initialize({
      client_id: this.clientIdValue,
      callback: this.handleCredentialResponse.bind(this)
    })

    google.accounts.id.renderButton(
      this.buttonTarget,
      { theme: 'outline', size: 'large' }
    )

    google.accounts.id.prompt()
  }

  async handleCredentialResponse (response) {
    const result = await fetchJson(this.verifyRouteValue, 'post', csrfParams({ token: response.credential }))

    if (result.status === 'ok' && result.redirect_to) {
      window.location.href = result.redirect_to
    }
  }
}
