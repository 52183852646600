import { VueController } from 'util/vue-controller'

// Connects to data-controller="contact-list-select"
export default class extends VueController {
  static targets = ['input', 'placeholder']

  static values = {
    account: String,
    name: {
      type: String,
      default: 'contact_list_id'
    },
    value: String
  }

  static vueComponent = 'contact-list-select'

  async connect () {
    if (!this.hasAccountValue) {
      throw new Error('account value is required')
    }

    if (this.element.nodeName === 'INPUT') {
      await this.#prepareInput(this.element)
    } else if (this.hasInputTarget) {
      await this.#prepareInput(this.inputTarget)
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.style.display = 'none'
    }

    await this.loadVueComponent()

    this.componentRoot.classList.remove(...['animate-pulse', 'bg-gray-200', 'rounded-md', 'w-96'])
  }

  disconnect () {
    this.unloadVueComponent()
  }

  valueValueChanged (newValue) {
    if (this.hasInputTarget) {
      this.inputTarget.value = newValue
    }
  }

  get props () {
    return {
      account: this.accountValue,
      name: this.nameValue,
      value: this.valueValue
    }
  }

  addNewList () {
    this.dispatch('add-list')
  }

  async #prepareInput (inputElement) {
    this.nameValue = inputElement.name
    this.valueValue = inputElement.value
    inputElement.style.display = 'none'

    const component = document.createElement('div')
    component.classList.add(...['min-h-12', 'w-96', 'rounded-md', 'bg-gray-200'])
    component.dataset.contactListField = inputElement.name
    inputElement.insertAdjacentElement('afterend', component)

    this.componentRoot = component
  }
}
