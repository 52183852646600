const slideover = `
  <div class="flex flex-col gap-gutter animate-pulse my-gutter-lg">
    <div class="h-2 bg-gray-200 rounded"></div>
    <div class="grid grid-cols-4 gap-4">
       <div class="h-2 bg-gray-200 rounded col-span-1"></div>
       <div class="h-2 bg-gray-200 rounded col-span-3"></div>
     </div>
    <div class="h-2 bg-gray-200 rounded"></div>
    <div class="grid grid-cols-3 gap-4">
       <div class="h-2 bg-gray-200 rounded col-span-2"></div>
       <div class="h-2 bg-gray-200 rounded col-span-1"></div>
     </div>
    <div class="h-2 bg-gray-200 rounded"></div>
    <div class="grid grid-cols-4 gap-4">
       <div class="h-2 bg-gray-200 rounded col-span-2"></div>
       <div class="h-2 bg-gray-200 rounded col-span-2"></div>
     </div>
    <div class="h-2 bg-gray-200 rounded"></div>
  </div>
`

const modal = `<div class="flex gap-gutter animate-pulse my-gutter-lg">
  <div>
    <div class="rounded-full h-12 w-12 bg-gray-200"></div>
  </div>
  <div class="flex-1 flex flex-col gap-4">
    <div class="h-6 bg-gray-200 rounded"></div>
    <div class="h-3 bg-gray-200 rounded"></div>
    <div class="h-3 bg-gray-200 rounded"></div>
    <div class="w-full flex gap-4 justify-end">
      <div class="h-10 w-32 bg-gray-200 rounded"></div>
      <div class="h-10 w-32 bg-gray-200 rounded"></div>
    </div>
  </div>
</div>`

const frame = `<div class="text-center flex flex-col gap-0.5">
  <h2 class="text-md font-medium">This content could not be loaded.</h2>
  <p>Please check your connection and try again.</p>
</div>`

export default class BlankState extends HTMLElement {
  connectedCallback () {
    this.innerHTML = this.template
  }

  get template () {
    switch (this.getAttribute('type')) {
      case 'slideover':
        return slideover

      case 'frame-missing':
        return frame

      default:
        return modal
    }
  }
}
