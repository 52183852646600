import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="builder"
export default class extends Controller {
  connect () {
    document.documentElement.addEventListener('turbo:frame-load', this.onLoad)
  }

  disconnect () {
    document.documentElement.removeEventListener('turbo:frame-load', this.onLoad)
  }

  onLoad = event => {
    window.scrollTo(0, 0)
  }
}
