let registered = false

export default async function (Quill) {
  if (registered) {
    return Quill
  }

  const BlockEmbed = Quill.import('blots/block/embed')

  // <rich-text-attachment>
  class RichTextFormat extends BlockEmbed {
    static blotName = 'rich-text'
    static tagName = 'RICH-TEXT-ATTACHMENT'

    static create (value) {
      const node = super.create(value)

      if (value.valid) {
        node.dataset.controller = 'rich-text-attachment'
        node.dataset.attachment = JSON.stringify(value)
        node.dataset.attachmentId = value.id
        node.dataset.attachmentType = value.type
        node.innerHTML = value.html
      }

      node.contentEditable = false
      node.classList.add('relative')
      node.classList.add('block')

      return node
    }

    static value (node) {
      let data = {}

      if ('attachment' in node.dataset) {
        data = JSON.parse(node.dataset.attachment)
        data.valid = true
      }

      data.html = node.innerHTML

      return data
    }

    removeAttachment () {
      const quill = this.instance

      const index = quill.getIndex(this)

      if (!index) {
        return
      }

      quill.removeFormat(index)
    }

    get instance () {
      return Quill.find(this.scroll.domNode.parentElement)
    }
  }

  Quill.register('formats/rich-text', RichTextFormat)

  registered = true

  return null
}
