export function fadeAndRemove (element) {
  return new Promise(resolve => {
    element.addEventListener('transitionend', () => {
      element.remove()
      resolve()
    })

    element.classList.add('transition-opacity', 'ease-in-out', 'duration-100')

    window.requestAnimationFrame(() => {
      element.classList.add('opacity-0')
    })
  })
}
