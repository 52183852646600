import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form-fill"
export default class extends Controller {
  static targets = ['field']

  fill (event) {
    event.preventDefault()

    const element = event.target.closest('[data-action]')
    const values = JSON.parse(element.dataset.formFillValues)

    this.fieldTargets.forEach(target => {
      if (values[target.id]) {
        target.value = values[target.id]
      }
    })
  }
}
