import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="highlight"
export default class extends Controller {
  static classes = ['active']
  static targets = ['item', 'update']

  change (event) {
    const link = event.target.closest('[data-highlight-target="item"]')

    if (!link) {
      return
    }

    this.highlightLink(link)
  }

  highlightLink (link) {
    link.classList.add(...this.activeClasses)

    this.itemTargets.forEach(target => {
      if (target !== link) {
        target.classList.remove(...this.activeClasses)
      }
    })
  }

  updateTargetConnected (target) {
    this.itemTargets.forEach(item => {
      if (item.dataset.value === target.dataset.value) {
        this.highlightLink(item)
      }
    })
  }

  highlight (value) {
    this.itemTargets.forEach(item => {
      if (item.dataset.value === value) {
        this.highlightLink(item)
      }
    })
  }
}
