import { Controller } from '@hotwired/stimulus'
import { readInheritableStaticValue } from 'util/class'

class VueController extends Controller {
  static vueComponent = ''

  async connect () {
    await this.loadVueComponent()
  }

  async disconnect () {
    await this.unloadVueComponent()
  }

  get componentKey () {
    return readInheritableStaticValue(this.constructor, 'vueComponent')
  }

  get vueTarget () {
    if (this.componentRoot) {
      return this.componentRoot
    }

    return this.element
  }

  // async load in vue js
  async loadVueComponent () {
    const {
      createApp,
      provide,
      h,
      defineAsyncComponent,
      reactive
    } = await import('vue')

    // Dynamically import the Vue component
    const Component = defineAsyncComponent(() => import(`../components/${this.componentKey}.vue`))

    const handler = (eventName, data) => this.#handler(eventName, data)

    const reactiveProps = reactive(Object.assign({}, this.props))

    const app = createApp({
      setup () {
        provide('handle', handler)
      },

      render: () => h(Component, reactiveProps)
    })

    this.reactiveProps = reactiveProps

    this.vueTarget.innerHTML = ''

    const element = document.createElement('div')
    this.vueTarget.appendChild(element)

    app.mount(this.vueTarget)

    this.instance = app
  }

  async unloadVueComponent () {
    if (this.instance) {
      this.instance.unmount()
      this.instance = null
    }
  }

  get props () {
    return {}
  }

  update (newProps) {
    for (const [key, value] of Object.entries(newProps)) {
      this.reactiveProps[key] = value
    }
  }

  #handler (eventName, data) {
    switch (eventName) {
      case 'value-changed':
        this.#handleValueChangedfromVue(data)
        break
    }
  }

  #handleValueChangedfromVue (data) {
    // iterate over all keys and values of data and set them to the controller
    for (const [key, value] of Object.entries(data)) {
      this[`${key}Value`] = value
    }
  }
}

export {
  VueController
}
