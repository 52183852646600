import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="calendar-month"
export default class extends Controller {
  static classes = ['selected']

  static targets = ['days', 'field']

  static values = {
    date: String
  }

  chooseDay (event) {
    const target = event.target.closest('[data-date]')

    if (!target) {
      return
    }

    this.dateValue = target.dataset.date

    if (!this.hasSelectedClass) {
      return
    }

    if (!this.hasDaysTarget) {
      return
    }

    this.daysTarget.querySelectorAll(`.${this.selectedClass}`).forEach(el => el.classList.remove(this.selectedClass))

    target.classList.add(this.selectedClass)
  }

  dateValueChanged () {
    if (this.hasFieldTarget) {
      this.fieldTarget.value = this.dateValue
    }
  }
}
