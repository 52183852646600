// app/javascript/controllers/scroll_controller.js
import { Controller } from '@hotwired/stimulus'

// scroll_controller.js
export default class extends Controller {
  connect () {
    this.scrollToBottom()

    // Set up observer
    this.observer = new window.MutationObserver(() => {
      this.scrollToBottom()
    })

    // Start observing changes to content
    this.observer.observe(this.element, {
      childList: true, // Watch for added/removed nodes
      subtree: true // Watch all descendants
    })
  }

  disconnect () {
    this.observer?.disconnect()
  }

  scrollToBottom () {
    this.element.scrollTop = this.element.scrollHeight
  }
}
