import { Controller } from '@hotwired/stimulus'
import { csrfParams } from 'util/params'
import { fetchTurboStream } from 'util/fetch'

// Connects to data-controller="drag"
export default class extends Controller {
  static values = {
    top: Number,
    left: Number,

    gridSize: {
      type: Number,
      default: 10
    },

    updateUrl: String,
    updateParam: String
  }

  async connect () {
    const InteractJs = await import('interactjs')
    const interact = InteractJs.default

    const grid = interact.snappers.grid({
      x: this.gridSizeValue,
      y: this.gridSizeValue
    })

    this.interaction = interact(this.element).draggable({
      allowFrom: '[data-draggable]',
      listeners: {
        move: this.#onMove,
        end: this.#onEnd
      },
      modifiers: [
        interact.modifiers.snap({
          targets: [grid]
        }),

        interact.modifiers.snapSize({
          targets: [
            { width: this.gridSizeValue },
            interact.snappers.grid({ width: this.gridSizeValue, height: this.gridSizeValue })
          ]
        }),

        interact.modifiers.restrict({
          restriction: 'parent',
          endOnly: true
        })
      ]
    })
  }

  disconnect () {
    if (this.interaction) {
      this.interaction.unset()
      this.interaction = null
    }
  }

  get position () {
    return {
      x: this.leftValue,
      y: this.topValue
    }
  }

  save () {
    if (!this.hasUpdateUrlValue) {
      return
    }

    const param = this.updateParamValue || 'position'

    // round to nearest 5/0 value
    this.topValue = Math.round(this.topValue / 5) * 5
    this.leftValue = Math.round(this.leftValue / 5) * 5

    const params = csrfParams({
      [`${param}[top]`]: this.topValue,
      [`${param}[left]`]: this.leftValue
    })

    fetchTurboStream(this.updateUrlValue, 'patch', params)
  }

  #onEnd = _event => {
    this.save()
  }

  #onMove = event => {
    const { position } = this

    position.x = Math.floor(position.x + event.dx)
    position.y = Math.floor(position.y + event.dy)

    event.target.style.transform = `translate(${position.x}px, ${position.y}px)`

    this.leftValue = position.x
    this.topValue = position.y
  }
}
