import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="thread"
export default class extends Controller {
  static targets = ['chat', 'message']

  messageTargetConnected () {
    this.chatTarget.scrollTop = this.chatTarget.scrollHeight
  }
}
