import { Controller } from '@hotwired/stimulus'

/*
  Connects to data-controller="visibility"

  Used to toggle items to/from hidden status based on a toggle event
*/
export default class extends Controller {
  static targets = ['hidden', 'visible']

  /* all hidden targets become visible, visible targets become hidden */
  invert (event) {
    if (event) {
      event.preventDefault()
    }

    const allTargets = this.element.querySelectorAll('[data-visibility-target]')

    let didFocus = false

    allTargets.forEach(target => {
      switch (target.dataset.visibilityTarget) {
        case 'hidden':
          target.dataset.visibilityTarget = 'visible'
          target.classList.remove('hidden')
          target.removeAttribute('aria-hidden')

          if (!didFocus) {
            const formControl = target.querySelector('.form-control')

            if (formControl) {
              formControl.focus()
              didFocus = true
            }
          }

          break
        case 'visible':
          target.dataset.visibilityTarget = 'hidden'
          target.classList.add('hidden')
          target.setAttribute('aria-hidden', 'true')
          break
      }
    })
  }

  toggle (event) {
    this.hiddenTargets.forEach(target => {
      target.classList.remove('hidden')
      target.setAttribute('aria-hidden', 'false')
    })

    this.visibleTargets.forEach(target => {
      target.classList.add('hidden')
      target.setAttribute('aria-hidden', 'true')
    })
  }
}
