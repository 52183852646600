import { VueController } from 'util/vue-controller'
import { strip } from 'util/string'

export default class extends VueController {
  static targets = ['field']

  static values = {
    integration: String,
    externalKey: String
  }

  static vueComponent = 'integration-contact-list-search'

  async connect () {
    if (!this.hasIntegrationValue) {
      throw new Error('integration value is required')
    }

    this.field.setAttribute('type', 'hidden')

    await this.loadVueComponent()
  }

  disconnect () {
    this.field.setAttribute('type', 'text')
    this.unloadVueComponent()
  }

  get field () {
    if (this.hasFieldTarget) {
      return this.fieldTarget
    } else {
      throw new Error('no field found')
    }
  }

  get placeholder () {
    return this.field.getAttribute('placeholder') ?? 'Search for a list...'
  }

  get props () {
    return {
      integration: this.integrationValue,
      placeholder: this.placeholder
    }
  }

  externalKeyValueChanged (value) {
    let externalKey = strip(`${value}`)

    if (externalKey === '' || externalKey === null || externalKey === undefined || externalKey === 'null') {
      externalKey = null
    }

    if (externalKey === null) {
      this.field.value = ''
    } else {
      this.field.value = externalKey
    }
  }
}
