import { filter, isUndefined, remove } from 'lodash-es'
import { Controller } from '@hotwired/stimulus'

const noop = () => {}

async function forEachAsync (array, callback) {
  await Promise.all(array.map(async target => {
    await callback(target)
  }))
}

function afterFinish (enterOrLeave, element) {
  return () => {
    if (enterOrLeave === 'enter') {
      element.style.display = ''
    } else {
      element.style.display = 'none'
    }
  }
}

function delay (ms) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms)
  })
}

function toClasses (str) {
  if (str === null || isUndefined(str)) {
    return []
  }

  const classes = str.toString().split(' ')
  return filter(classes, s => s !== '')
}

async function transition (element, event, callback = null) {
  const during = toClasses(element.dataset[event])
  const onStart = toClasses(element.dataset[`${event}Start`])
  const onEnd = toClasses(element.dataset[`${event}End`])

  if (callback === null) {
    callback = noop
  }

  const onShow = event === 'enter' ? callback : noop
  const onHide = event === 'leave' ? callback : noop

  const stages = {
    start () {
      element.classList.add(...onStart)
    },

    during () {
      element.classList.add(...during)
    },

    show () {
      onShow()
    },

    end () {
      element.classList.remove(...onStart)
      element.classList.add(...onEnd)
    },

    hide () {
      onHide()
    },

    cleanup () {
      element.classList.remove(...during)
      element.classList.remove(...onEnd)
    }
  }

  stages.start()
  stages.during()

  await nextAnimationFrame()

  const duration = Number(getComputedStyle(element).transitionDuration.replace(/,.*/, '').replace('s', '')) * 1000

  stages.show()

  await nextAnimationFrame()

  stages.end()

  await delay(duration)

  stages.hide()

  if (element.isConnected) {
    stages.cleanup()

    const event = new window.CustomEvent('transition:finished')
    element.dispatchEvent(event)
  }

  return true
}

async function nextAnimationFrame () {
  await new Promise(resolve => requestAnimationFrame(resolve))
}

export default class extends Controller {
  static targets = ['item', 'frame']

  static outlets = ['transition']

  static values = {
    key: String,
    activeItems: Object,
    frameUrl: String,
    trigger: String
  }

  async connect () {
    this.activeKeys = []

    document.addEventListener('turbo:before-cache', this.hideNow)

    if (this.key === null) {
      this.element.addEventListener('transition:perform', this.onToggle)
    } else {
      document.documentElement.addEventListener('transition:perform', this.onToggle)
    }

    if (this.triggerValue === 'load') {
      await delay(200)
      await this._performTransition(this.keyValue || 'any')
    }
  }

  disconnect () {
    document.removeEventListener('turbo:before-cache', this.hideNow)

    if (this.key === null) {
      this.element.removeEventListener('transition:perform', this.onToggle)
    } else {
      document.documentElement.removeEventListener('transition:perform', this.onToggle)
    }
  }

  get key () {
    if (this.hasKeyValue) {
      return this.keyValue
    }

    return null
  }

  hideNow = () => {
    this.itemTargets.forEach(target => {
      target.style.display = 'none'
    })
  }

  onToggle = (event) => {
    let key = 'any'

    // only require a key match if this controller is using them
    if (this.key !== null) {
      key = event.detail.key

      if (typeof key !== 'string') {
        return
      }

      if (this.key !== key) {
        return
      }
    }

    this._performTransition(key)
  }

  getValue (key) {
    return this.activeItemsValue[key] === true
  }

  async hide (event) {
    await forEachAsync(this.activeKeys, async key => {
      await this._performTransition(key)
    })

    this.activeKeys = []

    await forEachAsync(this.transitionOutlets, async outlet => {
      await outlet.hide(event)
    })

    this.#dispatch('hidden')
  }

  hideOnClickOutside ({ target }) {
    if (!this.element.contains(target)) {
      this.hide()
    }
  }

  async toggle (event) {
    event.preventDefault()

    const target = event.target.closest('[data-action]')

    if (!target) {
      return
    }

    const key = target.dataset.transitionKeyValue

    await this._performTransition(key)

    await forEachAsync(this.transitionOutlets, async outlet => {
      await outlet.toggle(event)
    })
  }

  updateFrame (eventName) {
    if (!this.hasFrameTarget) {
      return
    }

    if (!this.hasFrameUrlValue) {
      return
    }

    if (eventName === 'enter') {
      this.frameTarget.src = this.frameUrlValue
    } else {
      this.frameTarget.src = null
    }
  }

  async _performTransition (key) {
    const currentValue = this.getValue(key)
    const eventName = currentValue ? 'leave' : 'enter'
    const afterChange = []
    const usingMatchKey = true // this.key !== null

    if (this.hasFrameTarget) {
      this.updateFrame(eventName)
    }

    if (currentValue) {
      this.activeKeys = remove(this.activeKeys, key)
    } else {
      this.activeKeys.push(key)
    }

    await Promise.all(this.itemTargets.map(async target => {
      if (usingMatchKey && target.dataset.showOn === key) {
        if (eventName === 'enter') {
          target.style.display = ''
          target.classList.remove('hidden')
        } else {
          afterChange.push(async () => {
            await transition(target, eventName, () => {
              target.style.display = 'none'
            })
          })
        }
      }

      if (usingMatchKey && target.dataset.transitionOn !== key) {
        return
      }

      await transition(target, eventName, afterFinish(eventName, target))
    }))

    this.activeItemsValue = Object.assign({}, this.activeItemsValue, {
      [key]: !currentValue
    })

    await nextAnimationFrame()

    await Promise.all(afterChange.map(async func => func()))

    this.#dispatch('completed')

    return true
  }

  #dispatch (eventName, options = undefined) {
    const event = new window.CustomEvent(`transition:${eventName}`, options)
    this.element.dispatchEvent(event)
  }
}
