import { Turbo } from '@hotwired/turbo-rails'

export function handleLoadAction (streamElement) {
  const target = streamElement.getAttribute('target')
  const src = streamElement.getAttribute('src')

  if (!target || !src) {
    console.error('turbo-stream:load action requires both target and src attributes')
    return
  }

  Turbo.visit(src, { frame: target, action: 'advance' })
}

// override the default <turbo-stream> handler to add a few of our own utilities
export function handleTurboStreamRender (event) {
  const fallbackToDefaultActions = event.detail.render

  event.detail.render = streamElement => {
    if (streamElement.action === 'load') {
      handleLoadAction(streamElement)
    } else {
      fallbackToDefaultActions(streamElement)
    }
  }
}
