import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs-field"

// just listens for changes to a tab controller and updates a form field with the current tab key
export default class extends Controller {
  static values = {
    // to only watch for one tabset at a time, override this value to the tab controller identifier value
    tabset: {
      type: String,
      default: '*'
    }
  }

  connect () {
    window.addEventListener('tab:changed', this.onTabChanged)
  }

  disconnect () {
    window.removeEventListener('tab:changed', this.onTabChanged)
  }

  onTabChanged = event => {
    if (this.tabsetValue !== '*' && this.tabsetValue !== event.detail.tabset) {
      return
    }

    this.element.value = event.detail.key
  }
}
